import React from 'react';
import bannerImg from '../../assets/images/h3.png'
import { Link } from 'react-router-dom';
import { Navbar } from '../../components/Navbar';
import { Features } from '../../components/Features';
import { ClientsTwo } from '../../components/ClientsTwo';
import { Footer } from '../../components/Footer';
import { TypeAnimation } from 'react-type-animation';

export const Home: React.FC = () => {
  return (
    <>
      <Navbar />
      <section className="relative overflow-hidden pt-48 after:content-[''] after:absolute after:inset-0 after:mx-auto after:w-[56rem] after:h-[56rem] after:bg-gradient-to-tl after:to-amber-400/30  after:from-fuchsia-600/30 dark:after:to-amber-400/50 dark:after:from-fuchsia-600/50 after:blur-[200px] after:rounded-full after:-z-1">
        <div className="container relative z-2">
          <div className="grid grid-cols-1 text-center">
            <div className="">
              <h5 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-6xl mb-5">Honrando vidas pasadas <br />
                <TypeAnimation
                  sequence={[
                    'Manteniendo vivas las historias',
                    1000,
                    'Celebrando legados',
                    1000,
                    'Perpetuando memorias',
                    1000
                  ]}
                  wrapper="span"
                  speed={10}
                  className="typewrite bg-gradient-to-br from-amber-400 to-fuchsia-600 text-transparent bg-clip-text ms-4"
                  repeat={Infinity}
                />
              </h5>
              <p className="text-slate-400 dark:text-white/60 text-lg max-w-xl mx-auto">Un lugar para recordar y celebrar las historias de aquellos que nos dejaron huellas imborrables.</p>

              <div className="mt-6">
                <Link to="/buy" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-amber-400 hover:bg-amber-500 border-amber-400 hover:border-amber-500 text-white rounded-md">Ordena ahora</Link>

                <p className="text-slate-400 dark:text-white/60 text-md mt-6">"Bienvenido a nuestro santuario digital, donde las memorias perduran y las historias cobran vida."</p>
              </div>
            </div>
            <div className="relative mt-8 z-3 rounded-md shadow bg-white p-2">
              <img src={bannerImg} alt="" className="mover" />
            </div>
          </div>
        </div>

        <div className="relative after:content-[''] after:absolute lg:after:-bottom-40 after:-bottom-28 after:end-0 after:start-0 after:mx-auto xl:after:w-[56rem] lg:after:w-[48rem] md:after:w-[32rem] after:w-[22rem] xl:after:h-[56rem] lg:after:h-[48rem] md:after:h-[32rem] after:h-[22rem] after:border-2 after:border-dashed after:border-slate-700/10 dark:after:border-slate-200/10 after:rounded-full after:-z-1 before:content-[''] before:absolute lg:before:-bottom-72 before:-bottom-56 before:end-0 before:start-0 before:mx-auto xl:before:w-[72rem] lg:before:w-[64rem] md:before:w-[48rem] before:w-[24rem] xl:before:h-[72rem] lg:before:h-[64rem] md:before:h-[48rem] before:h-[24rem] before:border-2 before:border-dashed before:border-slate-700/10 dark:before:border-slate-200/10 before:rounded-full before:-z-1"></div>
      </section>

      <section className="relative md:pt-24 pt-8">
        <Features classlist="container relative" />
      </section>

      <section className="relative md:py-24 py-8">
        <ClientsTwo />
      </section>
      <Footer />
    </>

  );
}
