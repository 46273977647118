import { gql } from '@apollo/client';

export const ME = gql`
  query me {
    me {
      id
      email
      username
      blocked
    }
  }
`;

export const PROFILES_BY_USER_ID = gql`
  query profiles($userId: ID!, $page: Int, $pageSize: Int) {
    profiles(
      filters: { users_permissions_user: { id: { eq: $userId } } }
      pagination: { page: $page, pageSize: $pageSize }
    ) {
      data {
        id
        attributes {
          name
          lastname
          title
          headline
          phrase
          bio
          birth_date
          death_date
          obituary_link
          legacy_link
          video_link
          video_links
          photo {
            data {
              attributes {
                url
              }
            }
          }
          gallery {
            data {
              id
              attributes {
                url
              }
            }
          }
          relationship {
            data {
              id
              attributes {
                name
              }
            }
          }
          medallion {
            data {
              id
              attributes {
                slug
                profiles {
                  data {
                    id
                  }
                }
              }
            }
          }
          grave_address
          grave_location
          grave_latitude
          grave_longitude
          tributes{
            data{
              id
              attributes{
                name
                email
                comment
                createdAt
                media{
                  data {
                    id
                    attributes{
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  } 
`;

export const RELATIONSHIPS = gql`
  query relationships($page: Int, $pageSize: Int) {
    relationships(
      pagination: { page: $page, pageSize: $pageSize }
    ) {
      data {
        id
        attributes {
          name
        }
      }
    }
  } 
`;

export const MEDALLIONS_BY_SLUG = gql`
  query medallion($slug: String!, $page: Int, $pageSize: Int) {
    medallions(
      filters: { slug: { eq: $slug } }
      pagination: { page: $page, pageSize: $pageSize }
    ) {
      data {
        id
        attributes {
          slug
          registered
          link
          users_permissions_user {
            data {
              id
              attributes {
                username
                email
                photo {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
          profiles {
            data {
              id
              attributes {
                name
                lastname
                title
                headline
                phrase
                bio
                birth_date
                death_date
                obituary_link
                legacy_link
                video_links
                grave_address
                grave_location
                grave_latitude
                grave_longitude
                photo {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                gallery {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                relationship {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const MEDALLIONS_BY_USER_ID = gql`
  query medallions($userId: ID!, $page: Int, $pageSize: Int) {
    medallions(
      filters: { users_permissions_user: { id: { eq: $userId } } }
      pagination: { page: $page, pageSize: $pageSize }
    ){
      data{
        id
        attributes{
          users_permissions_user {
            data {
              id 
              attributes {
                username
                email
                photo {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
          profiles {
            data {
              id
            }
          }
          registered
          link
          slug
        }
      }
    }
  }
`;

export const TRIBUTES_BY_PROFILE_ID = gql`
query tributes ($profileId: ID!, $page: Int, $pageSize: Int) {
  tributes(
    filters: {profile: {id: {eq: $profileId}}}
    pagination: { page: $page, pageSize: $pageSize }
  ){
    data {
      id
      attributes{
        name
        email
        comment
        createdAt
        profile {
          data {
            id
          }
        }
        media {
          data {
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
`;

export const CONFIG = gql`
  query config{
    config{
      data{
        id
        attributes{
          email
          phone
          address
          whatsapp
          facebook
          instagram
          twitter
        }
      }
    }
  }
`;



