import React, { useEffect, useState, useCallback } from 'react';
import { useUser } from '../../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { Sidebar } from '../../components/Sidebar';
import { useQueryRelationship } from "../../models/relationship/useQueryRelationship";
import { CgAsterisk } from "react-icons/cg";
import { useCreateProfile } from "../../models/profiles/useCreateProfile";
import { useQueryMe } from '../../models/me/useQueryMe';
import { useQueryMedallionsByUser } from '../../models/medallions/useQueryMedallionsByUser';

interface Field {
  id: number;
  value: string;
}

export const AddProfile: React.FC = () => {
  const { user } = useUser();
  const me = useQueryMe();
  const navigate = useNavigate();
  const [photo, setPhoto] = useState<File>();
  const [gallery, setGallery] = useState<File[]>();
  const [name, setName] = useState<string>('');
  const [lastname, setLastname] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [phrase, setPhrase] = useState<string>('');
  const [bio, setBio] = useState<string>('');
  const [headline, setHeadline] = useState<string>('');
  const [obituaryLink, setObituaryLink] = useState<string>('');
  const [legacyLink, setLegacyLink] = useState<string>('');
  //const [videoLinks, setvideoLinks] = useState<string>('');
  const [birthDate, setBirthDate] = useState<Date>();
  const [deathDate, setDeathDate] = useState<Date>();
  const [relationship, setRelationship] = useState<number>();
  const [medallion, setMedallion] = useState<number>();
  const [, setDisabled] = useState(true);
  const [errorRequest, setErrorRequest] = useState('');
  const [graveAddress, setGraveAddress] = useState<string>('');
  const [graveLocation, setGraveLocation] = useState<string>('');
  const [graveLatitude, setGraveLatitude] = useState<string>('');
  const [graveLongitude, setGraveLongitude] = useState<string>('');
  const { responseData } = useQueryRelationship();
  const medallions = useQueryMedallionsByUser(me.responseData?.id);
  const [fields, setFields] = useState<Field[]>([{ id: 1, value: "" }]);
  const { handleCreateProfile, loading, errorResponse, data } = useCreateProfile({
    name,
    lastname,
    relationship,
    medallion,
    photo,
    birthDate,
    deathDate,
    title,
    headline,
    obituaryLink,
    legacyLink,
    //videoLink,
    videoLinks: fields,
    bio,
    phrase,
    gallery,
    user: me.responseData?.id,
    graveAddress,
    graveLocation,
    graveLatitude,
    graveLongitude
  });

  const handlePhotoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const photoFile = event.target.files[0];
      setPhoto(photoFile);
    }
  };
  const handleGalleryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const galeryFiles = Array.from(event.target.files);
      setGallery(galeryFiles);
    }
  };
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const handleLastnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastname(event.target.value);
  };
  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };
  const handlePhraseChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhrase(event.target.value);
  };
  const handleBioChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setBio(event.target.value);
  };
  const handleHeadlineChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHeadline(event.target.value);
  };
  const handleObituaryLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setObituaryLink(event.target.value);
  };
  const handleLegacyLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLegacyLink(event.target.value);
  };
  /*   const handlevideoLinksChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setvideoLinks(event.target.value);
    }; */
  const handleBirthDateChange = (date: Date | null) => {
    if (date) {
      setBirthDate(date);
    }
  };
  const handleDeathDateChange = (date: Date | null) => {
    if (date) {
      setDeathDate(date);
    }
  };
  const handleRelationshipChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRelationship(Number(event.target.value));
  };
  const handleMedallionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setMedallion(Number(event.target.value));
  };
  const handleGraveAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGraveAddress(event.target.value);
  };
  const handleGraveLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGraveLocation(event.target.value);
  };
  const handleGraveLatitudeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGraveLatitude(event.target.value);
  };
  const handleGraveLongitudeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGraveLongitude(event.target.value);
  };
  const validateFields = useCallback((): boolean => {
    if (!photo || !name || !lastname || !birthDate || !deathDate || !relationship || !medallion) {
      return true;
    }
    return false;
  }, [name, lastname, photo, birthDate, deathDate, relationship, medallion]);
  const handleSubmit = async () => {
    if (gallery && gallery.length > 20) {
      setErrorRequest("La galería tiene un máximo de 20 imágenes")
    } else if (validateFields()) {
      setErrorRequest("Los campos requeridos son obligatorios")
    } else {
      setErrorRequest('');
      //setvideoLinks(JSON.stringify(fields))
      try {
        await handleCreateProfile();
      } catch (error) {
        setErrorRequest('Error al crear el perfil');
      }
    }
  };
  const handleChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const values = [...fields];
    values[index].value = e.target.value;
    setFields(values);
  };
  const handleAddField = () => {
    const newField: Field = {
      id: fields.length + 1,
      value: ""
    };
    setFields([...fields, newField]);
  };
  const handleRemoveField = (index: number) => {
    const values = [...fields];
    values.splice(index, 1);
    setFields(values);
  };

  useEffect(() => {
    if (gallery && gallery.length > 20) {
      setDisabled(true)
    } else if (validateFields()) {
      setDisabled(true)
    } else {
      setDisabled(false);
    }
  }, [validateFields, gallery]);

  useEffect(() => {
    if (!user) {
      navigate('/');
    }
  }, [navigate, user]);

  useEffect(() => {
    if (data) {
      navigate('/dashboard');
    }
  }, [data, navigate]);

  return (
    <div className="flex">
      <Sidebar>
        <main className="flex flex-col flex-1 bg-white">
          <section className="relative py-8">
            <div className="lg:col-span-5">
              <div className="bg-white dark:bg-slate-900 p-12">
                <h3 className="mb-6 text-2xl leading-normal font-semibold">Agregar Perfil</h3>
                <div className="grid lg:grid-cols-12 lg:gap-6">
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="name" className="font-semibold flex "><CgAsterisk className="text-amber-400" />Nombre:</label>
                    <input
                      name="name"
                      id="name"
                      type="text"
                      className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      value={name}
                      onChange={handleNameChange}
                    />
                  </div>
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="lastname" className="font-semibold flex "><CgAsterisk className="text-amber-400" />Apellido:</label>
                    <input
                      name="lastname"
                      id="lastname"
                      type="text"
                      className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      value={lastname}
                      onChange={handleLastnameChange}
                    />
                  </div>
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="birthDate" className="font-semibold flex "><CgAsterisk className="text-amber-400" />Fecha de Nacimiento:</label>
                    <input
                      name="birthDate"
                      id="birthDate"
                      type="date"
                      className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      onChange={(e) => handleBirthDateChange(new Date(e.target.value))}
                    />
                  </div>
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="deathDate" className="font-semibold flex "><CgAsterisk className="text-amber-400" />Fecha de Muerte:</label>
                    <input
                      name="deathDate"
                      id="deathDate"
                      type="date"
                      className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      onChange={(e) => handleDeathDateChange(new Date(e.target.value))}
                    />
                  </div>
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="title" className="font-semibold">Título:</label>
                    <input
                      name="title"
                      id="title"
                      type="text"
                      className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      placeholder="Ejemplo: Jr - Sr"
                      value={title}
                      onChange={handleTitleChange}
                    />
                  </div>
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="relationship" className="font-semibold flex "><CgAsterisk className="text-amber-400" />Relación:</label>
                    <select
                      name="relationship"
                      id="relationship"
                      className="form-select w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      value={relationship}
                      onChange={handleRelationshipChange}
                    >
                      <option value="">Seleccione una relación</option>
                      {responseData?.map((relationship) => (
                        <option key={relationship.id} value={relationship.id}>
                          {relationship.attributes.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="photo" className="font-semibold flex "><CgAsterisk className="text-amber-400" />Foto de perfil:</label>
                    <input
                      name="photo"
                      id="photo"
                      type="file"
                      className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      onChange={handlePhotoChange}
                    />
                  </div>
                </div>
                <h3 className="my-6 text-2xl leading-normal font-semibold">Agregar información conmemorativa</h3>
                <div className="grid lg:grid-cols-12 lg:gap-6">
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="gallery" className="font-semibold">{`Galeria de imagenes (max: 20):`}</label>
                    <input
                      name="gallery"
                      id="gallery"
                      type="file"
                      multiple
                      className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      onChange={handleGalleryChange}
                    />
                  </div>
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="headline" className="font-semibold">Cabecera:</label>
                    <input
                      name="headline"
                      id="headline"
                      type="text"
                      className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      placeholder="Ejemplo: En memoria de..."
                      value={headline}
                      onChange={handleHeadlineChange}
                    />
                  </div>
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="obituary" className="font-semibold">Link Obituario:</label>
                    <input
                      name="obituaryLink"
                      id="obituaryLink"
                      type="text"
                      className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      value={obituaryLink}
                      onChange={handleObituaryLinkChange}
                    />
                  </div>
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="obituary" className="font-semibold">Link Legado:</label>
                    <input
                      name="legacyLink"
                      id="legacyLink"
                      type="text"
                      className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      value={legacyLink}
                      onChange={handleLegacyLinkChange}
                    />
                  </div>
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="obituary" className="font-semibold">Link Vídeos Youtube:</label>
                    {/*     <input
                      name="videoLinks"
                      id="videoLinks"
                      type="text"
                      className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      value={videoLinks}
                      onChange={handlevideoLinksChange}
                    /> */}
                    {fields.map((field, index) => (
                      <div key={field.id} className="flex items-center mb-2">
                        <input
                          name="videoLinks"
                          id="videoLinks"
                          type="text"
                          className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                          value={field.value}
                          onChange={(e) => handleChange(index, e)}
                        />
                        {fields.length > 1 && (
                          <button
                            className="py-2 px-5 ml-2 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-red-400 hover:bg-red-500 border-red-400 hover:border-red-500 text-white rounded-md"
                            onClick={() => handleRemoveField(index)}
                          >
                            X
                          </button>
                        )}
                      </div>
                    ))}
                    <button
                      className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-amber-400 hover:bg-amber-500 border-amber-400 hover:border-amber-500 text-white rounded-md"
                      onClick={handleAddField}
                    >
                      Otro vídeo
                    </button>
                  </div>
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="phrase" className="font-semibold">Frase o Poema:</label>
                    <input
                      name="phrase"
                      id="phrase"
                      type="text"
                      className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      value={phrase}
                      onChange={handlePhraseChange}
                    />
                  </div>
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="medallion" className="font-semibold flex"><CgAsterisk className="text-amber-400" />Medallón QR:</label>
                    <select
                      name="medallion"
                      id="medallion"
                      className="form-select w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      value={medallion}
                      onChange={handleMedallionChange}
                    >
                      <option value="">Seleccione un medallón</option>
                      {medallions.responseData?.map((medallion) => (
                        medallion.attributes.profiles.data?.length < 3 && (
                          <option key={medallion.id} value={medallion.id}>
                            {medallion.attributes.slug}
                          </option>
                        )
                      ))}
                    </select>
                  </div>
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="bio" className="font-semibold">Biografía:</label>
                    <textarea
                      name="bio"
                      id="bio"
                      className="form-textarea w-full py-2 px-3 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      value={bio}
                      onChange={handleBioChange}
                      rows={4}
                      placeholder="Escribe algo acerca del fallecido..."
                    />
                  </div>
                </div>
                <h3 className="my-6 text-2xl leading-normal font-semibold">Agregar información del sepulcro</h3>
                <div className="grid lg:grid-cols-12 lg:gap-6">
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="graveLongitude" className="font-semibold">Nombre Cementerio:</label>
                    <input
                      name="graveLongitude"
                      id="graveLongitude"
                      type="text"
                      placeholder="Ejemplo: CAPILLA OSARIO LA RESURECCIÓN"
                      className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      value={graveLongitude}
                      onChange={handleGraveLongitudeChange}
                    />
                  </div>
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="graveAddress" className="font-semibold">Dirección Cementerio:</label>
                    <input
                      name="graveAddress"
                      id="graveAddress"
                      type="text"
                      className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      value={graveAddress}
                      onChange={handleGraveAddressChange}
                    />
                  </div>
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="graveLocation" className="font-semibold">Dirección Sepulcro:</label>
                    <input
                      name="graveLocation"
                      id="graveLocation"
                      type="text"
                      placeholder='Ejemplo: E02 Grupo209 -Lote 03'
                      className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      value={graveLocation}
                      onChange={handleGraveLocationChange}
                    />
                  </div>

                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="graveLatitude" className="font-semibold">Link Mapa:</label>
                    <input
                      name="graveLatitude"
                      id="graveLatitude"
                      type="text"
                      className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      value={graveLatitude}
                      onChange={handleGraveLatitudeChange}
                    />
                  </div>

                </div>
                {errorRequest &&
                  <div className="lg:col-span-6 mb-5">
                    <label className="font-semibold text-red-600">{errorRequest}</label>
                  </div>
                }
                {errorResponse &&
                  <div className="lg:col-span-6 mb-5">
                    <label className="font-semibold text-red-600">{errorResponse.message}</label>
                  </div>
                }
                <div className="lg:col-span-6 mb-5">
                  <label htmlFor="bio" className="font-semibold flex "><CgAsterisk className="text-amber-400" />Campos obligatorios</label>
                </div>
                <button
                  disabled={loading}
                  onClick={handleSubmit}
                  className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-amber-400 hover:bg-amber-500 border-amber-400 hover:border-amber-500 text-white rounded-md"
                >
                  {loading ? "Enviando" : "Enviar"}
                </button>
              </div>
            </div>
          </section>
        </main>
      </Sidebar>
    </div>
  );
};
