import { CREATE_TRIBUTE } from '../../graphql/mutations';
import { UPLOAD } from '../../graphql/mutations';
import { useMutation } from '@apollo/client';
import type { TributeEntity } from '../../types/TributeEntity';
import type { UploadFileEntity } from '../../types/UploadFileEntity';

interface useCreateTributeProps {
  name: string;
  email: string;
  comment: string;
  media?: File;
  profile?: number;
}

interface UploadFileEntityResponse {
  data: {
    upload: {
      data: UploadFileEntity
    }
  }
}

export const useCreateTribute = (requestData: useCreateTributeProps) => {
  const [createTribute, { loading, error, data }] = useMutation(CREATE_TRIBUTE);
  const [upload, apolloUpload] = useMutation(UPLOAD);

  const handleCreateTribute = async () => {
    try {
      if (requestData.media) {
        const media = await upload({ variables: { file: requestData.media } }) as UploadFileEntityResponse;
        await createTribute({
          variables: {
            data: {
              name: requestData.name,
              email: requestData.email,
              comment: requestData.comment,
              media: media.data.upload.data.id,
              profile: Number(requestData.profile),
            }
          }
        }) as TributeEntity;
      }
      else {
        await createTribute({
          variables: {
            data:{
              name: requestData.name,
              email: requestData.email,
              comment: requestData.comment,
              profile: Number(requestData.profile),
            }
          }
        }) as TributeEntity;
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return { handleCreateTribute, loading: loading || apolloUpload.loading, errorResponse: error, data };
};
