import React, { useState } from 'react';
import type { ProfileEntity } from '../../types/ProfileEntity';
import { FaCross } from 'react-icons/fa';
import { Memorial } from '../Memorial/Memorial';

interface MemorialProps {
  profiles: ProfileEntity[];
}

export const MemorialSelect: React.FC<MemorialProps> = ({ profiles }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <section className="relative py-8 bg-gradient-to-b from-amber-400/20 dark:from-amber-400/40 to-transparent">
      <div className="relative flex justify-center flex-col">
        <ul className="flex w-fit mx-auto flex-wrap justify-center text-center m-2 bg-amber-200 shadow rounded-md">
          {profiles.map((profile, index) => (
            <li role="presentation" className={`${profiles.length === 2 && "md:w-1/2"} ${profiles.length === 3 && "md:w-1/3"} w-full inline-blockrounded-md border`}>
              <button className={`${activeIndex === index ? "text-white bg-amber-400" : " hover:text-amber-400 hover:bg-gray-50"} px-5 py-3 text-start rounded-md w-full h-full duration-500 `} onClick={() => setActiveIndex(index)}>
                <h5 className="text-base font-semibold">{profile.attributes.name + " " + profile.attributes.lastname}</h5>
                <span className="flex profiles-center mt-2">
                  <FaCross className="h-4 w-4 pr-2" />
                  <span className="text-sm">{profile.attributes.death_date.toString()}</span>
                </span>
              </button>
            </li>))}
        </ul>

        <div id="StarterContent" className="mt-6">
          {activeIndex === 0 && <Memorial profile={profiles[0]} />}
          {activeIndex === 1 && <Memorial profile={profiles[1]} />}
          {activeIndex === 2 && <Memorial profile={profiles[2]} />}
        </div>
      </div>
    </section>
  );
};
