import { REGISTER } from '../../graphql/mutations';
import { UPDATE_MEDALLION } from '../../graphql/mutations';
import { useMutation } from '@apollo/client';
import { useUser } from '../../contexts/UserContext';
import type { UsersPermissionsLoginPayload } from "../../types/UsersPermissionsLoginPayload";
import type { MedallionEntity } from "../../types/MedallionEntity";

export const useRegister = (username: string, email: string, password: string, medallionId?: number) => {
  const [register, { loading, error }] = useMutation(REGISTER);
  const [updateMedallion, apolloMedallion] = useMutation(UPDATE_MEDALLION);
  const { setUser } = useUser();

  const handleRegister = async () => {
    try {
      const { data } = await register({
        variables: { username, email, password },
      });
      const { jwt, user } = data.register as UsersPermissionsLoginPayload;
      setUser({ token: jwt });
      await updateMedallion({
        variables: {
          id: medallionId,
          data: {
            "registered": true,
            "users_permissions_user": user.id
          }
        }
      }) as MedallionEntity;
    } catch (error) {
      console.error('Registration failed:', error);
    }
  };

  return { handleRegister, loading: loading || apolloMedallion.loading, error };
};
