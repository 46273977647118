import React from "react";
import about1 from '../../assets/images/n2.png'
import about2 from '../../assets/images/n1.png'
import about3 from '../../assets/images/n3.png'

interface FeaturesProps {
  classlist: string
}
export const Features: React.FC<FeaturesProps> = ({ classlist }) => {
  const featuresData = [
    {
      image: about1,
      title: "Escanea el Medallón Angel Qr",
      desc: "Comience su viaje escaneando el Medallón Angel Qr con su teléfono inteligente. Se abre una ventana a un mundo lleno de recuerdos."
    },
    {
      image: about2,
      title: "Configurar perfil",
      desc: "Tómese un momento para configurar el perfil. Cada paso es una pincelada en el lienzo del recuerdo."
    },
    {
      image: about3,
      title: "Recuerda",
      desc: "La página conmemorativa está activa. Recuerda, revive los momentos especiales y honra el legado de tus seres queridos para siempre. Explora los mensajes y tributos dejados por familiares, amigos y personas que fueron inspiradas por su vida."
    },
  ]
  return (
    <>
      <div className={classlist}>
        <div className="grid grid-cols-1 pb-6 text-center">
          <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">QR + recuerdos = <br /> legados imortales con <span className="bg-gradient-to-br from-amber-400 to-fuchsia-600 text-transparent bg-clip-text">AngelsQR</span></h3>

          <p className="text-slate-400 max-w-xl mx-auto">Cada vida tiene una historia: descubre las biografías de quienes nos dejaron huellas imborrables.</p>
        </div>

        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
          {featuresData.map((item, index) => {
            return (
              <div className="relative overflow-hidden bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800" key={index}>
                <div className="p-6 pb-0 relative overflow-hidden after:content-[''] after:absolute after:inset-0 after:mx-auto after:w-72 after:h-72 after:bg-gradient-to-tl after:to-amber-400 after:from-fuchsia-600 after:blur-[80px] after:rounded-full">
                  <img src={item.image} className="relative rounded-t-md shadow-md dark:shadow-slate-700 z-1" alt="" />
                </div>

                <div className="p-6">
                  <h5 className="text-lg font-semibold">{item.title}</h5>
                  <p className="text-slate-400 mt-3">{item.desc}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}