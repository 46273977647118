import { UPDATE_PROFILE } from '../../graphql/mutations';
import { UPLOAD } from '../../graphql/mutations';
import { MULTIPLE_UPLOAD } from '../../graphql/mutations';
import { useMutation } from '@apollo/client';
import type { ProfileEntity } from '../../types/ProfileEntity';
import type { UploadFileEntity } from '../../types/UploadFileEntity';

interface Field {
  id: number;
  value: string;
}
interface FieldGalery {
  id: number;
  attributes: {
    url: string;
  };
}
interface useUpdateProfileProps {
  name: string;
  lastname: string;
  title?: string;
  photo?: File;
  gallery?: File[];
  fieldsGalery?: FieldGalery[]; 
  headline?: string;
  obituaryLink?: string;
  legacyLink?: string;
  videoLink?: string;
  videoLinks?: Field[];
  bio?: string;
  birthDate?: Date;
  deathDate?: Date;
  phrase?: string;
  relationship?: number;
  medallion?: number;
  user?: number;
  graveAddress?: string;
  graveLocation?: string;
  graveLatitude?: string;
  graveLongitude?: string;
}

interface ProfileInput {
  name?: string
  lastname?: string
  title: string
  photo: number
  headline: string
  obituary_link: string
  legacy_link: string
  bio: string
  birth_date?: Date
  death_date?: Date
  phrase: string
  relationship?: number
  users_permissions_user?: number
  gallery: number[]
  video_link: string
  video_links: JSON
  medallion?: number
  tributes: number[]
  grave_address: string
  grave_location: string
  grave_latitude: string
  grave_longitude: string
}

interface UploadFileEntityResponse {
  data: {
    upload: {
      data: UploadFileEntity
    }
  }
}

interface MultipleUploadFileEntityResponse {
  data: {
    multipleUpload: [{
      data: UploadFileEntity
    }]
  }
}

export const useUpdateProfile = (requestData: useUpdateProfileProps, profileId: number) => {
  const [updateProfile, { loading, error, data }] = useMutation(UPDATE_PROFILE);
  const [upload, apolloUpload] = useMutation(UPLOAD);
  const [multipleUpload, apolloMultipleUpload] = useMutation(MULTIPLE_UPLOAD);

  const handleUpdateProfile = async () => {
    try {
      let photoId: number | undefined = undefined;
      let galleryIds: number[] = []
      let fieldGalleryIds: number[] = []
      let input = {
        name: requestData.name,
        lastname: requestData.lastname,
        title: requestData.title,
        headline: requestData.headline,
        obituary_link: requestData.obituaryLink,
        legacy_link: requestData.legacyLink,
        //video_link: requestData.videoLink,
        video_links: requestData.videoLinks,
        bio: requestData.bio,
        birth_date: requestData.birthDate?.toISOString().split('T')[0],
        death_date: requestData.deathDate?.toISOString().split('T')[0],
        phrase: requestData.phrase,
        relationship: Number(requestData.relationship),
        medallion: Number(requestData.medallion),
        users_permissions_user: Number(requestData.user),
        grave_address: requestData.graveAddress,
        grave_location: requestData.graveLocation,
        grave_latitude: requestData.graveLatitude,
        grave_longitude: requestData.graveLongitude,
      } as unknown as ProfileInput
      if (requestData.photo) {
        const result = await upload({ variables: { file: requestData.photo } }) as UploadFileEntityResponse;
        photoId = result.data.upload.data.id
        input.photo = photoId
      }
      if (requestData.fieldsGalery) {
        fieldGalleryIds = requestData.fieldsGalery.map( fieldGalery => Number(fieldGalery.id))
        input.gallery = fieldGalleryIds
      }
      if (requestData.gallery && requestData.gallery.length <= 20) {
        const result = await multipleUpload({ variables: { files: requestData.gallery } }) as MultipleUploadFileEntityResponse;
        galleryIds = result.data.multipleUpload.map(upload => Number(upload.data.id))
        input.gallery = [...fieldGalleryIds, ...galleryIds]
      }
      await updateProfile({
        variables: {
          id: Number(profileId),
          data: input
        }
      }) as ProfileEntity;
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return { handleUpdateProfile, loading: loading || apolloUpload.loading || apolloMultipleUpload.loading, errorResponse: error, data };
};
