import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from '../../assets/images/logos/light-1.png'
import { useLogin } from '../../models/login/useLogin'
import { useNavigate } from 'react-router-dom';
import { useUser } from "../../contexts/UserContext";

export const Login: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const { handleLogin, error, loading } = useLogin(email, password);

  const validateFields = useCallback((): boolean => {
    if (!email || !password) {
      return true;
    }
    return false;
  }, [email, password]);

  useEffect(() => {
    if (validateFields()) {
      setDisabled(true)
    } else {
      setDisabled(false);
    }
  }, [validateFields]);


  useEffect(() => {
    if (user) {
      navigate('/dashboard');
    }
  }, [navigate, user]);

  return (
    <>
      <section className="relative overflow-hidden h-screen flex items-center bg-[url('../../assets/images/bg/n1.png')] bg-no-repeat bg-left bg-cover bg-fixed">
        <div className="absolute inset-0 bg-slate-950/20"></div>
        <div className="container relative">
          <div className="md:flex justify-center">
            <div className="lg:w-1/3 md:w-2/4">
              <div className="rounded shadow bg-white dark:bg-slate-900 p-6">
                <Link to="/">
                  <img src={logo} alt="" />
                </Link>

                <h5 className="mt-6 text-xl font-semibold">Inicia sesión en tu cuenta</h5>

                <form className="text-start mt-4">
                  <div className="grid grid-cols-1">
                    <div className="mb-4">
                      <label className="font-semibold" htmlFor="LoginEmail">Correo Electrónico:</label>
                      <input
                        id="LoginEmail"
                        type="email"
                        className="form-input mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>

                    <div className="mb-4">
                      <label className="font-semibold" htmlFor="LoginPassword">Contraseña:</label>
                      <input
                        id="LoginPassword"
                        type="password"
                        className="form-input mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>

                    <div className="flex justify-between mb-4">
                      <div className="flex items-center mb-0">
                        <input
                          className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-amber-400 focus:border-amber-300 focus:ring focus:ring-offset-0 focus:ring-amber-200 focus:ring-opacity-50 cursor-pointer me-2"
                          type="checkbox"
                          value=""
                          id="RememberMe"
                          checked={rememberMe}
                          onChange={() => setRememberMe(!rememberMe)}
                        />
                        <label className="form-checkbox-label text-slate-400 cursor-pointer" htmlFor="RememberMe">Recuérdame</label>
                      </div>
                      <p className="text-slate-400 mb-0"><Link to="/forgotpassword" className="text-slate-400">¿Olvidaste la contraseña?</Link></p>
                    </div>

                    <div className="mb-4 text-center">
                      {error && <label className="font-semibold text-red-600">{error.message}</label>}
                    </div>

                    <div className="mb-4">
                      <input
                        className="py-2 px-5 inline-block tracking-wide border align-middle duration-500 text-base text-center bg-amber-400 hover:bg-amber-500 border-amber-400 hover:border-amberbg-amber-500 text-white rounded-md w-full"
                        value={loading ? "Iniciando" : "Iniciar sesión"}
                        onClick={handleLogin}
                        disabled={disabled || loading}
                      />
                    </div>

                    <div className="mb-4">
                      <Link
                        to="/"
                        className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-gray-800/5 hover:bg-gray-800 border-gray-800/10 hover:border-gray-800 text-gray-800 dark:text-white hover:text-white rounded-md w-full"
                      >
                        Regresar
                      </Link>
                    </div>

                    {/* <div className="text-center">
                      <span className="text-slate-400 me-2">¿No tienes una cuenta?</span> <Link to="/signup" className="text-slate-900 dark:text-white font-bold inline-block">Regístrate</Link>
                    </div> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Switcher /> */}
    </>
  );
}
