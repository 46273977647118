import React from "react";
import { FiCheckCircle } from '../../assets/icons/vander'
import { Link } from "react-router-dom";
import bannerImg from '../../assets/images/h9.png'
import { useQueryConfig } from "../../models/config/useQueryConfig";

export const Pricing: React.FC = () => {
  const { responseData } = useQueryConfig();

  return (
    <>
      <div className="flex justify-center mt-6">
        <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-800 w-full md:w-1/3">
          <div className="p-6">
            <h5 className="text-2xl leading-normal font-semibold">Medallón QR</h5>
            <img src={bannerImg} alt="" className="mover" />
            <p className="text-slate-400 mt-2">Nuestros operadores te atenderán</p>

            <div className="relative">
              <div className="flex mt-4">
                <span className="text-lg font-semibold">$</span>
                <span className="">
                  <input type="hidden" id="professional-amount" className="form-control" />
                  <p className="text-5xl font-semibold mb-0 ms-1 line-through" id="pro-amt">750 Bs</p>
                  <p className="text-5xl font-semibold mb-0 ms-1" id="pro-amt">350 Bs</p>
                  <p className="text-slate-400 uppercase text-xs">para siempre</p>
                </span>
              </div>

              <div className="relative mt-4">
                <label htmlFor="professional-price" className="form-label"></label>
                <span className="font-semibold text-lg absolute end-0 -top-5">
                  <input type="hidden" id="professional-update" className="form-control" />
                  <span className=""></span>
                </span>
              </div>
            </div>
            <div className="mt-4">
              <Link to={responseData?.attributes.whatsapp ? responseData?.attributes.whatsapp : "/"} target="_blank" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-amber-400 hover:bg-amber-500 border-amber-400 hover:border-amber-500 text-white rounded">Contactanos</Link>
            </div>
          </div>

          <div className="p-6 bg-gray-50 dark:bg-slate-800">
            <ul className="list-none text-slate-400">
              <li className="font-semibold text-slate-900 dark:text-white text-sm uppercase">Contenido:</li>
              <li className="flex items-center mt-2"><FiCheckCircle className="text-green-600 h-[18px] w-[18px] me-2" /> <span className="text-slate-900 dark:text-white mx-1 font-semibold">Hasta 3 perfiles</span> con 1 solo Medallón</li>
              <li className="flex items-center mt-2"><FiCheckCircle className="text-green-600 h-[18px] w-[18px] me-2" /> <span className="text-slate-900 dark:text-white me-1 font-semibold">Caja deslizable</span> de lujo</li>
              <li className="flex items-center mt-2"><FiCheckCircle className="text-green-600 h-[18px] w-[18px] me-2" /> <span className="text-slate-900 dark:text-white me-1 font-semibold">1 Medallón</span> en aluminio de alta calidad</li>
              <li className="flex items-center mt-2"><FiCheckCircle className="text-green-600 h-[18px] w-[18px] me-2" /> <span className="text-slate-900 dark:text-white me-1 font-semibold">Pegatina Permanente</span> 3M</li>
              <li className="flex items-center mt-2"><FiCheckCircle className="text-green-600 h-[18px] w-[18px] me-2" /> <span className="text-slate-900 dark:text-white mx-1 font-semibold">Soporte</span> 24/7</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
