import React, { useEffect, useState, useCallback } from 'react';
import { useUser } from '../../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { Sidebar } from '../../components/Sidebar';
import { CgAsterisk } from "react-icons/cg";
import { useUpdateUser } from "../../models/config/useUpdateUser";
import { useQueryMe } from '../../models/me/useQueryMe';

export const Config: React.FC = () => {
  const { user } = useUser();
  const me = useQueryMe();
  const navigate = useNavigate();
  const [photo, setPhoto] = useState<File>();
  const [username, setUsername] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  //const [password, setPassword] = useState<string>('');
  const [, setDisabled] = useState(true);
  const [errorRequest, setErrorRequest] = useState('');
  const { handleUpdateUser, loading, errorResponse, data } = useUpdateUser({
    username,
    email,
    photo,
  }, me.responseData?.id);
  const handlePhotoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const photoFile = event.target.files[0];
      setPhoto(photoFile);
    }
  };
  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const validateFields = useCallback((): boolean => {
    if (!username || !email ) {
      return true;
    }
    return false;
  }, [username, email]);
  const handleSubmit = async () => {
    if (validateFields()) {
      setErrorRequest("Los campos requeridos son obligatorios")
    } else {
      setErrorRequest('');
      try {
        await handleUpdateUser();
      } catch (error) {
        setErrorRequest('Error al crear el perfil');
      }
    }
  };

  useEffect(() => {
    if (validateFields()) {
      setDisabled(true)
    } else {
      setDisabled(false);
    }
  }, [validateFields]);

  useEffect(() => {
    if (!user) {
      navigate('/');
    }
  }, [navigate, user]);

/*   useEffect(() => {
    if (data) {
      navigate('/dashboard');
    }
  }, [data, navigate]); */

  return (
    <div className="flex">
      <Sidebar>
        <main className="flex flex-col flex-1 bg-white">
          <section className="relative py-8">
            <div className="lg:col-span-5">
              <div className="bg-white dark:bg-slate-900 p-12">
                <h3 className="mb-6 text-2xl leading-normal font-semibold">Configuración de cuenta</h3>
                <div className="grid lg:grid-cols-12 lg:gap-6">
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="name" className="font-semibold flex "><CgAsterisk className="text-amber-400" />Nombre de usuario:</label>
                    <input
                      name="username"
                      id="username"
                      type="text"
                      className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      value={username}
                      onChange={handleUsernameChange}
                    />
                  </div>
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="lastname" className="font-semibold flex "><CgAsterisk className="text-amber-400" />Correo electrónico:</label>
                    <input
                      name="email"
                      id="email"
                      type="text"
                      className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      value={email}
                      onChange={handleEmailChange}
                    />
                  </div>
                  <div className="lg:col-span-6 mb-5">
                    <label htmlFor="photo" className="font-semibold flex "><CgAsterisk className="text-amber-400" />Foto de perfil:</label>
                    <input
                      name="photo"
                      id="photo"
                      type="file"
                      className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2"
                      onChange={handlePhotoChange}
                    />
                  </div>
                </div>
                {errorRequest &&
                  <div className="lg:col-span-6 mb-5">
                    <label className="font-semibold text-red-600">{errorRequest}</label>
                  </div>
                }
                {errorResponse &&
                  <div className="lg:col-span-6 mb-5">
                    <label className="font-semibold text-red-600">{errorResponse.message}</label>
                  </div>
                }
                {data &&
                  <div className="lg:col-span-6 mb-5">
                    <label className="font-semibold text-green-600">Cuenta modificada exitosamente</label>
                  </div>
                }
                <div className="lg:col-span-6 mb-5">
                  <label htmlFor="bio" className="font-semibold flex "><CgAsterisk className="text-amber-400" />Campos obligatorios</label>
                </div>
                <button
                  disabled={loading}
                  onClick={handleSubmit}
                  className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-amber-400 hover:bg-amber-500 border-amber-400 hover:border-amber-500 text-white rounded-md"
                >
                  {loading ? "Enviando" : "Enviar"}
                </button>
              </div>
            </div>
          </section>
        </main>
      </Sidebar>
    </div>
  );
};
