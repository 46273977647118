import { CREATE_PROFILE } from '../../graphql/mutations';
import { UPLOAD } from '../../graphql/mutations';
import { MULTIPLE_UPLOAD } from '../../graphql/mutations';
import { useMutation } from '@apollo/client';
import type { ProfileEntity } from '../../types/ProfileEntity';
import type { UploadFileEntity } from '../../types/UploadFileEntity';

interface Field {
  id: number;
  value: string;
}
interface useCreateProfileProps {
  name: string;
  lastname: string;
  title?: string;
  photo?: File;
  gallery?: File[];
  headline?: string;
  obituaryLink?: string;
  legacyLink?: string;
  videoLink?: string;
  videoLinks?: Field[];
  bio?: string;
  birthDate?: Date;
  deathDate?: Date;
  phrase?: string;
  relationship?: number;
  medallion?: number;
  user?: number;
  graveAddress?: string;
  graveLocation?: string;
  graveLatitude?: string;
  graveLongitude?: string;
}

interface UploadFileEntityResponse {
  data: {
    upload: {
      data: UploadFileEntity
    }
  }
}

interface MultipleUploadFileEntityResponse {
  data: {
    multipleUpload: [{
      data: UploadFileEntity
    }]
  }
}

/* interface AnimationData {
  data: {
    code: number,
    data: number,
    msg: string,
    time: number,
    requestId: string
  } | null,
  error: Error | null;
} */

export const useCreateProfile = (requestData: useCreateProfileProps) => {
  const [createProfile, { loading, error, data }] = useMutation(CREATE_PROFILE);
  const [upload, apolloUpload] = useMutation(UPLOAD);
  const [multipleUpload, apolloMultipleUpload] = useMutation(MULTIPLE_UPLOAD);
  //const [animationData, setAnimationData] = useState<AnimationData>({ data: null, error: null });


  const handleCreateProfile = async () => {
    try {
      const photo = await upload({ variables: { file: requestData.photo } }) as UploadFileEntityResponse;
      /*  const animationData = await axios.get(`https://www.cutout.pro/api/v1/faceDriven/submitTaskByUrl?imageUrl=${process.env.REACT_APP_API_URL + photo.data.upload.data.attributes.url}&templateId=2`, {
         headers: {
           'APIKEY': process.env.REACT_APP_CUTOUT_API_KEY
         },
         responseType: 'json'
       }); */
      let galleryIds: number[] = []
      if (requestData.gallery && requestData.gallery.length <= 20) {
        const result = await multipleUpload({ variables: { files: requestData.gallery } }) as MultipleUploadFileEntityResponse;
        galleryIds = result.data.multipleUpload.map(upload => Number(upload.data.id))
      }
      /*   await axios.get(`https://www.cutout.pro/api/v1/faceDriven/getTaskInfo?taskId=${animationData.data?.data}`, {
          headers: {
            'APIKEY': process.env.REACT_APP_CUTOUT_API_KEY
          },
          responseType: 'json'
        }); */
      await createProfile({
        variables: {
          name: requestData.name,
          lastname: requestData.lastname,
          title: requestData.title,
          photo: photo.data.upload.data.id,
          gallery: galleryIds,
          headline: requestData.headline,
          obituary_link: requestData.obituaryLink,
          legacy_link: requestData.legacyLink,
          //video_link: requestData.videoLink,
          video_links: requestData.videoLinks,
          bio: requestData.bio,
          birth_date: requestData.birthDate?.toISOString().split('T')[0],
          death_date: requestData.deathDate?.toISOString().split('T')[0],
          phrase: requestData.phrase,
          relationship: requestData.relationship,
          medallion: requestData.medallion,
          users_permissions_user: Number(requestData.user),
          grave_address: requestData.graveAddress,
          grave_location: requestData.graveLocation,
          grave_latitude: requestData.graveLatitude,
          grave_longitude: requestData.graveLongitude,
        }
      }) as ProfileEntity;
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return { handleCreateProfile, loading: loading || apolloUpload.loading || apolloMultipleUpload.loading, errorResponse: error, data };
};
