import React, { createContext, useState, useContext, useEffect } from 'react';

interface User {
  token: string;
}

interface UserContextType {
  user: User | null;
  setUser: (user: User) => void;
  clearUser: () => void;
}

const UserContext = createContext<UserContextType>({
  user: null,
  setUser: () => {},
  clearUser: () => {},
});

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  
  const handleSetUser = async (newUser: User) => {
    await localStorage.setItem('token', newUser?.token);
    setUser(newUser);
  };

  const handleClearUser = async () => {
    await localStorage.removeItem("token");
    setUser(null);
  };

  useEffect(() => {
    const checkToken = async () => {
      const token = await localStorage.getItem('token');
      if (token) {
        setUser({ token });
      }
    };

    checkToken();
  }, []); 

  return (
    <UserContext.Provider
      value={{ user, setUser: handleSetUser, clearUser: handleClearUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);