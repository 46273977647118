import React from 'react';
import './App.css';
import '../assets/css/tailwind.css'
import '../assets/css/materialdesignicons.min.css'
import { Home } from './Home';
import { Contact } from './Contact';
import { Buy } from './Buy';
import { AboutUs } from './AboutUs';
import { Dashboard } from './Dashboard';
import { Config } from './Config';
import { AddProfile } from './AddProfile';
import { Login } from './Login';
import { ForgotPassword } from './ForgotPassword';
import { Error } from './Error';
import { Routes, Route } from 'react-router-dom';
import { Medallion } from './Medallion';
import { EditProfile } from './EditProfile';
//import { CreateMedallion } from './CreateMedallion';

export const App: React.FC = () => {

  return (
    <>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/config' element={<Config />} />
        <Route path='/forgotpassword' element={<ForgotPassword />} />
        <Route path='/addprofile' element={<AddProfile />} />
        <Route path='/editprofile/:id' element={<EditProfile />} />
        {/* <Route path='/199752783469704hugoblanco' element={<CreateMedallion />} /> */}
        <Route path='/login' element={<Login />} />
        <Route path='/error' element={<Error />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/buy' element={<Buy />} />
        <Route path='/aboutus' element={<AboutUs />} />
        <Route path='*' element={<Error />} />
        <Route path="/medallion/:slug" element={<Medallion />} />
      </Routes>
    </>

  );
}
