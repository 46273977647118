import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from '../../assets/images/logos/light-1.png'
import { useRegister } from '../../models/register/useRegister'
import { useNavigate } from 'react-router-dom';
import { useUser } from "../../contexts/UserContext";

interface SignupProps {
  medallionId?: number
}

export const Signup: React.FC<SignupProps> = ({ medallionId }) => {
  const navigate = useNavigate();
  const { user } = useUser();
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [disabled, setDisabled] = useState(true);
  const { handleRegister, error, loading } = useRegister(username, email, password, medallionId);

  const validatePassword = (password: string) => {
    if (password.length === 0) {
      setPasswordError('');
      return true;
    }
    if (password.length < 8 || password.length > 16) {
      setPasswordError('La contraseña debe tener entre 8 y 16 caracteres');
      return false;
    }
    if (!password.match(/[a-zA-Z]/) || !password.match(/[0-9]/)) {
      setPasswordError('La contraseña debe contener al menos una letra y un número');
      return false;
    }
    setPasswordError('');
    return true;
  };

  const validateFields = useCallback((): boolean => {
    if (!email || !username) {
      return true;
    }
    return false;
  }, [email, username]);

  useEffect(() => {
    setDisabled(!(password && validatePassword(password) && !validateFields()));
  }, [password, validateFields]);

  useEffect(() => {
    if (user) {
      navigate('/dashboard');
    }
  }, [navigate, user]);

  return (
    <>
      <section className="relative overflow-hidden h-screen flex items-center bg-[url('../../assets/images/bg/n1.png')] bg-no-repeat bg-left bg-cover bg-fixed">
        <div className="absolute inset-0 bg-slate-950/20"></div>
        <div className="container relative">
          <div className="md:flex justify-center">
            <div className="lg:w-1/3 md:w-2/4">
              <div className="rounded shadow bg-white dark:bg-slate-900 p-6">
                <Link to="/">
                  <img src={logo} alt="" />
                </Link>

                <h5 className="mt-6 text-xl font-semibold">Crear una cuenta</h5>

                <form className="text-start mt-4">
                  <div className="grid grid-cols-1">
                    <div className="mb-4">
                      <label className="font-semibold" htmlFor="RegisterName">Tu Nombre:</label>
                      <input
                        id="RegisterName"
                        type="text"
                        className="form-input mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0"
                        placeholder=""
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </div>

                    <div className="mb-4">
                      <label className="font-semibold" htmlFor="LoginEmail">Correo Electrónico:</label>
                      <input
                        id="LoginEmail"
                        type="email"
                        className="form-input mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0"
                        placeholder=""
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>

                    <div className="mb-4">
                      <label className="font-semibold" htmlFor="LoginPassword">Contraseña:</label>
                      <input
                        id="LoginPassword"
                        type="password"
                        className="form-input mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0"
                        placeholder=""
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>

                    <div className="mb-4">
                      <div className="flex items-center w-full mb-0">
                        <input
                          className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-amber-400 focus:border-amber-300 focus:ring focus:ring-offset-0 focus:ring-amber-200 focus:ring-opacity-50 me-2 cursor-pointer"
                          type="checkbox"
                          value=""
                          id="AcceptT&C"
                          checked={acceptedTerms}
                          onChange={() => setAcceptedTerms(!acceptedTerms)}
                        />
                        <label className="form-check-label text-slate-400 cursor-pointer" htmlFor="AcceptT&C">Acepto <Link to="" className="text-amber-400">Términos y Condiciones</Link></label>
                      </div>
                    </div>

                    <div className="mb-4 text-center">
                      {passwordError !== '' && <label className="font-semibold text-red-600">{passwordError}</label>}
                      {error && <label className="font-semibold text-red-600">{error.message}</label>}
                    </div>

                    <div className="mb-4">
                      <input
                        className="py-2 px-5 inline-block tracking-wide border align-middle duration-500 text-base text-center bg-amber-400 hover:bg-amber-500 border-amber-400 hover:border-amberbg-amber-500 text-white rounded-md w-full"
                        value={!loading ? "Registrar" : "Registrando"}
                        onClick={handleRegister}
                        disabled={loading || disabled || !acceptedTerms}
                      />
                    </div>

                    <div className="mb-4">
                      <Link
                        to="/"
                        className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-gray-800/5 hover:bg-gray-800 border-gray-800/10 hover:border-gray-800 text-gray-800 dark:text-white hover:text-white rounded-md w-full"
                      >
                        Regresar
                      </Link>
                    </div>

                    {/*  <div className="text-center">
                      <span className="text-slate-400 me-2">¿Ya tienes una cuenta? </span> <Link to="/login" className="text-slate-900 dark:text-white font-bold inline-block">Iniciar sesión</Link>
                    </div> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*  <Switcher /> */}
    </>
  );
}
