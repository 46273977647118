import { UPDATE_USER, UPLOAD } from '../../graphql/mutations';
import { useMutation } from '@apollo/client';
import type { UsersPermissionsUserEntity } from '../../types/UsersPermissionsUserEntity';
import type { UploadFileEntity } from '../../types/UploadFileEntity';

interface useUpdateUserProps {
  email: string
  username: string
  photo?: File
  password?: string
}

interface UsersPermissionsUserInput {
  email: string
  username: string
  photo?: number
  password?: string
}

interface UploadFileEntityResponse {
  data: {
    upload: {
      data: UploadFileEntity
    }
  }
}
export const useUpdateUser = (requestData: useUpdateUserProps, userId: number | undefined) => {
  const [updateUser, { loading, error, data }] = useMutation(UPDATE_USER);
  const [upload, apolloUpload] = useMutation(UPLOAD);


  const handleUpdateUser = async () => {
    try {
      let photoId: number | undefined = undefined;
      let input = {
        username: requestData.username,
        email: requestData.email,
      } as UsersPermissionsUserInput
      if (requestData.photo) {
        const result = await upload({ variables: { file: requestData.photo } }) as UploadFileEntityResponse;
        photoId = result.data.upload.data.id
        input.photo = photoId
      }
      if (requestData.password) {
        input.password = requestData.password
      }

      await updateUser({
        variables: {
          id: Number(userId),
          data: input
        }
      }) as UsersPermissionsUserEntity;
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return { handleUpdateUser, loading: loading || apolloUpload.loading, errorResponse: error, data };
};
