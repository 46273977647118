import { ME } from '../../graphql/query';
import { useQuery } from '@apollo/client';
import type { UsersPermissionsMe } from "../../types/UsersPermissionsMe";

export const useQueryMe = () => {
  const { loading, data } = useQuery(ME, {
    fetchPolicy: 'network-only',
  });
  let responseData: UsersPermissionsMe | undefined = undefined;

  if (!loading) {
    responseData = data.me
  }

  return { responseData };
};
