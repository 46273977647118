import { PROFILES_BY_USER_ID } from '../../graphql/query';
import { useQuery } from '@apollo/client';
import type { ProfileEntity } from '../../types/ProfileEntity';

export const useQueryProfilesByUser = (userId: number | undefined) => {
  let responseData: ProfileEntity[] | undefined = undefined;
  const { loading, data } = useQuery(PROFILES_BY_USER_ID, {
    variables: { userId: userId, pageSize: 50 },
    skip: !userId,
    fetchPolicy: 'network-only', 
  });

  if (!loading && userId) {
    responseData = data.profiles.data as ProfileEntity[]
  }

  return { responseData, loading };
};
