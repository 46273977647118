import { DELETE_TRIBUTE } from '../../graphql/mutations';
import { useMutation } from '@apollo/client';
import type { TributeEntity } from '../../types/TributeEntity';

export const useDeleteTribute = (tributeId?: number) => {
    const [deleteTribute, { loading, error }] = useMutation(DELETE_TRIBUTE);
    let responseData: TributeEntity | undefined = undefined;
    const handleDeleteTribute = async () => {
        try {
            const { data } = await deleteTribute({ variables: { id: tributeId } });
            responseData = data.deleteTribute.data as TributeEntity
            return responseData;
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return { handleDeleteTribute, loading, error };
};
