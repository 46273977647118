import { CONFIG } from '../../graphql/query';
import { useQuery } from '@apollo/client';
import type { ConfigEntity } from '../../types/ConfigEntity';

export const useQueryConfig = () => {
  let responseData: ConfigEntity | undefined = undefined;
  const { loading, data } = useQuery(CONFIG, {
    variables: { pageSize: 50 },
  });

  if (!loading ) {
    responseData = data.config.data
  }

  return { responseData, loading };
};
