import { LOGIN } from '../../graphql/mutations';
import { useMutation } from '@apollo/client';
import { useUser } from '../../contexts/UserContext';
import type { UsersPermissionsLoginPayload } from "../../types/UsersPermissionsLoginPayload";

export const useLogin = (email: string, password: string) => {
  const [login, { loading, error }] = useMutation(LOGIN);
  const { setUser } = useUser();

  const handleLogin = async () => {
    try {
      const { data } = await login({
        variables: { identifier: email, password },
      });
      const { jwt } = data.login as UsersPermissionsLoginPayload;
      setUser({ token: jwt });
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  return { handleLogin, loading, error };
};
