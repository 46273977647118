import { MEDALLIONS_BY_USER_ID } from '../../graphql/query';
import { useQuery } from '@apollo/client';
import type { MedallionEntity } from '../../types/MedallionEntity';

export const useQueryMedallionsByUser = (userId: number | undefined) => {
  let responseData: MedallionEntity[] | undefined = undefined;
  const { loading, data } = useQuery(MEDALLIONS_BY_USER_ID, {
    variables: { userId: userId, pageSize: 50 },
    skip: !userId,
    fetchPolicy: 'network-only', 
  });

  if (!loading && userId) {
    responseData = data.medallions.data as MedallionEntity[]
  }

  return { responseData, loading };
};
