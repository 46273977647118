import React, { useEffect, useState } from 'react';
import { useUser } from '../../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { useQueryMe } from "../../models/me/useQueryMe";
import { useQueryProfilesByUser } from "../../models/profiles/useQueryProfilesByUser";
import { Sidebar } from "../../components/Sidebar";
import { CardProfile } from "../../components/CardProfile";
import { Spinner } from "../../components/Spinner";
import { useDeleteProfile } from '../../models/profiles/useDeleteProfile';
import type { ProfileEntity } from '../../types/ProfileEntity';

export const Dashboard: React.FC = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const me = useQueryMe();
  const queryProfilesByUser = useQueryProfilesByUser(me.responseData?.id);
  const [profiles, setProfiles] = useState<ProfileEntity[] | undefined>(undefined);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [profileToDelete, setProfileToDelete] = useState<ProfileEntity | undefined>(undefined);
  const { handleDeleteProfile, loading } = useDeleteProfile(profileToDelete?.id);

  const handleSubmit = async () => {
    try {
      if (profileToDelete) {
        const deleteProfile = await handleDeleteProfile();
        const updateProfiles = profiles?.filter(profile => profile.id !== deleteProfile?.id);
        setProfiles(updateProfiles)
        setShowDeleteModal(false);
        navigate('/dashboard');
      }
    } catch (error) {
      console.error(error)
    }
  };

  useEffect(() => {
    if (!user) {
      navigate('/');
    }
  }, [navigate, user]);

  useEffect(() => {
    if (queryProfilesByUser.responseData) {
      setProfiles(queryProfilesByUser.responseData);
    }
  }, [queryProfilesByUser.responseData]);


  return (
    <div className="flex">
      <Sidebar>
        {queryProfilesByUser.loading ?
          <Spinner />
          :
          <main className="flex flex-col flex-1 bg-white">
            <section className="relative md:py-24 py-16">
              <div className="container relative">
                {profiles?.length === 0 ?
                  <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
                    <h3 className="mb-6 text-2xl leading-normal font-semibold">Agrega tu primer perfil</h3>
                  </div> :
                  <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
                    {profiles?.map((item, index) => {
                      return <CardProfile profile={item} index={index} setProfileToDelete={setProfileToDelete} setShowDeleteModal={setShowDeleteModal} key={index} />
                    })}
                  </div>}

              </div>
            </section>
            {showDeleteModal && (
              <div className="fixed top-0 left-0 w-[80%] h-full bg-black bg-opacity-50 flex justify-center items-center">
                <div className="bg-white p-6 rounded-md shadow-lg animate-fade-in">
                  <p className="text-lg font-semibold mb-4">¿Estás seguro de que deseas eliminar este perfil?</p>
                  <div className="flex justify-end space-x-4">
                    <button disabled={loading} onClick={() => setShowDeleteModal(false)} className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md">Cancelar</button>
                    <button disabled={loading} onClick={handleSubmit} className="px-4 py-2 bg-amber-400 text-white rounded-md">Eliminar</button>
                  </div>
                </div>
              </div>
            )}
          </main>
        }
      </Sidebar>
    </div>
  );
};
