import { MEDALLIONS_BY_SLUG } from '../../graphql/query';
import { useQuery } from '@apollo/client';
import type { MedallionEntity } from '../../types/MedallionEntity';

export const useQueryMedallionsBySlug = (slug: string | undefined) => {
  let responseData: MedallionEntity[] | undefined = undefined;
  const { loading, data } = useQuery(MEDALLIONS_BY_SLUG, {
    variables: { slug: slug, pageSize: 50 },
    skip: !slug
  });

  if (!loading && slug) {
    responseData = data.medallions.data as MedallionEntity[]
  }

  return { responseData, loading };
};
