import React from "react";
import { Link } from "react-router-dom";
import Client1 from '../../assets/images/client/01.jpg'
import Client2 from '../../assets/images/client/02.jpg'
import Client3 from '../../assets/images/client/03.jpg'
import Client4 from '../../assets/images/client/04.jpg'
import Client5 from '../../assets/images/client/05.jpg'
import Client6 from '../../assets/images/client/06.jpg'
import Client7 from '../../assets/images/client/07.jpg'
import Client8 from '../../assets/images/client/08.jpg'

export const ClientsTwo: React.FC = () => {
  return (
    <>
      <div className="container relative">
        <div className="grid grid-cols-1 pb-6 text-center">
          <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Testimonios</h3>

          <p className="text-slate-400 max-w-xl mx-auto">Se partícipe y testigo junto a nosotros y nuestros productos.</p>
        </div>
      </div>

      <div className="container-fluid relative overflow-hidden">
        <div className="grid grid-cols-1 mt-6">
          <div className="slider relative overflow-hidden m-auto mb-4 before:content-[''] before:absolute before:top-0 before:start-0 before:z-2 after:content-[''] after:absolute after:top-0 after:end-0 after:z-2">
            <div className="slide-track flex items-center">
              <div className="slide h-auto md:w-[360px] w-72 m-2">
                <ul className="space-y-4">
                  <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                    <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                      <img src={Client1} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                      <div className="ps-4">
                        <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Thomas Israel</Link>
                        <p className="text-slate-400">User</p>
                      </div>
                    </div>

                    <div className="mt-6">
                      <p className="text-slate-400">Escanear el código QR me permitió sentirme cerca de mi abuela, como si estuviera hablando con ella de nuevo.</p>
                      <ul className="list-none mb-0 text-amber-400 mt-2">
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                      </ul>
                    </div>
                  </li>

                  <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                    <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                      <img src={Client2} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                      <div className="ps-4">
                        <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Barbara McIntosh</Link>
                        <p className="text-slate-400">User</p>
                      </div>
                    </div>

                    <div className="mt-6">
                      <p className="text-slate-400">Ver fotos y videos de mi padre en el medallón QR me trajo una gran alegría y me hizo recordar los mejores momentos que pasamos juntos.</p>
                      <ul className="list-none mb-0 text-amber-400 mt-2">
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="slide h-auto md:w-[360px] w-72 m-2">
                <ul className="space-y-4">
                  <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                    <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                      <img src={Client3} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                      <div className="ps-4">
                        <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Carl Oliver</Link>
                        <p className="text-slate-400">User</p>
                      </div>
                    </div>

                    <div className="mt-6">
                      <p className="text-slate-400">El medallón QR es un regalo invaluable que me permite mantener vivo el recuerdo de mi esposa y compartir su historia con nuestros hijos.</p>
                      <ul className="list-none mb-0 text-amber-400 mt-2">
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                      </ul>
                    </div>
                  </li>

                  <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                    <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                      <img src={Client4} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                      <div className="ps-4">
                        <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Miguel Fernández</Link>
                        <p className="text-slate-400">User</p>
                      </div>
                    </div>

                    <div className="mt-6">
                      <p className="text-slate-400">Gracias al medallón QR, pude conocer detalles de la vida de mi abuelo que nunca antes había escuchado.</p>
                      <ul className="list-none mb-0 text-amber-400 mt-2">
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="slide h-auto md:w-[360px] w-72 m-2">
                <ul className="space-y-4">
                  <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                    <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                      <img src={Client5} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                      <div className="ps-4">
                        <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Sofía Martínez</Link>
                        <p className="text-slate-400">User</p>
                      </div>
                    </div>

                    <div className="mt-6">
                      <p className="text-slate-400">El código QR me permitió acceder a información sobre la obra de mi tío, un artista que dejó un gran legado.</p>
                      <ul className="list-none mb-0 text-amber-400 mt-2">
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                      </ul>
                    </div>
                  </li>

                  <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                    <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                      <img src={Client6} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                      <div className="ps-4">
                        <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Elena López</Link>
                        <p className="text-slate-400">User</p>
                      </div>
                    </div>

                    <div className="mt-6">
                      <p className="text-slate-400">El medallón QR es una herramienta muy útil para investigadores y genealogistas, ya que permite acceder a información histórica de una manera rápida y sencilla.</p>
                      <ul className="list-none mb-0 text-amber-400 mt-2">
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="slide h-auto md:w-[360px] w-72 m-2">
                <ul className="space-y-4">
                  <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                    <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                      <img src={Client7} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                      <div className="ps-4">
                        <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Carlos Jiménez</Link>
                        <p className="text-slate-400">User</p>
                      </div>
                    </div>

                    <div className="mt-6">
                      <p className="text-slate-400">Me encanta la idea del medallón QR, es una forma moderna y original de honrar la memoria de nuestros seres queridos.</p>
                      <ul className="list-none mb-0 text-amber-400 mt-2">
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                      </ul>
                    </div>
                  </li>

                  <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                    <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                      <img src={Client8} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                      <div className="ps-4">
                        <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">María López</Link>
                        <p className="text-slate-400">User</p>
                      </div>
                    </div>

                    <div className="mt-6">
                      <p className="text-slate-400">Recuerda a tus seres queridos de una forma especial con el medallón QR.</p>
                      <ul className="list-none mb-0 text-amber-400 mt-2">
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="slide h-auto md:w-[360px] w-72 m-2">
                <ul className="space-y-4">
                  <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                    <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                      <img src={Client1} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                      <div className="ps-4">
                        <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Iñaki Gabilondo</Link>
                        <p className="text-slate-400">User</p>
                      </div>
                    </div>

                    <div className="mt-6">
                      <p className="text-slate-400">Me parece una idea muy original y creativa. Es una forma de darle un nuevo significado a las tumbas y de convertirlas en espacios interactivos.</p>
                      <ul className="list-none mb-0 text-amber-400 mt-2">
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                      </ul>
                    </div>
                  </li>

                  <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                    <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                      <img src={Client2} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                      <div className="ps-4">
                        <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Carmen Mola</Link>
                        <p className="text-slate-400">User</p>
                      </div>
                    </div>

                    <div className="mt-6">
                      <p className="text-slate-400">El medallón QR es una iniciativa que me parece muy interesante y valiosa. Es una forma de mantener viva la memoria de nuestros seres queridos y de compartir su legado con las nuevas generaciones.</p>
                      <ul className="list-none mb-0 text-amber-400 mt-2">
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="slide h-auto md:w-[360px] w-72 m-2">
                <ul className="space-y-4">
                  <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                    <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                      <img src={Client1} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                      <div className="ps-4">
                        <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Thomas Israel</Link>
                        <p className="text-slate-400">User</p>
                      </div>
                    </div>

                    <div className="mt-6">
                      <p className="text-slate-400">Escanear el código QR me permitió sentirme cerca de mi abuela, como si estuviera hablando con ella de nuevo.</p>
                      <ul className="list-none mb-0 text-amber-400 mt-2">
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                      </ul>
                    </div>
                  </li>

                  <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                    <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                      <img src={Client2} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                      <div className="ps-4">
                        <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Barbara McIntosh</Link>
                        <p className="text-slate-400">User</p>
                      </div>
                    </div>

                    <div className="mt-6">
                      <p className="text-slate-400">Ver fotos y videos de mi padre en el medallón QR me trajo una gran alegría y me hizo recordar los mejores momentos que pasamos juntos.</p>
                      <ul className="list-none mb-0 text-amber-400 mt-2">
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="slide h-auto md:w-[360px] w-72 m-2">
                <ul className="space-y-4">
                  <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                    <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                      <img src={Client3} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                      <div className="ps-4">
                        <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Carl Oliver</Link>
                        <p className="text-slate-400">User</p>
                      </div>
                    </div>

                    <div className="mt-6">
                      <p className="text-slate-400">El medallón QR es un regalo invaluable que me permite mantener vivo el recuerdo de mi esposa y compartir su historia con nuestros hijos.</p>
                      <ul className="list-none mb-0 text-amber-400 mt-2">
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                      </ul>
                    </div>
                  </li>

                  <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                    <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                      <img src={Client4} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                      <div className="ps-4">
                        <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Miguel Fernández</Link>
                        <p className="text-slate-400">User</p>
                      </div>
                    </div>

                    <div className="mt-6">
                      <p className="text-slate-400">Gracias al medallón QR, pude conocer detalles de la vida de mi abuelo que nunca antes había escuchado.</p>
                      <ul className="list-none mb-0 text-amber-400 mt-2">
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="slide h-auto md:w-[360px] w-72 m-2">
                <ul className="space-y-4">
                  <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                    <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                      <img src={Client5} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                      <div className="ps-4">
                        <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Sofía Martínez</Link>
                        <p className="text-slate-400">User</p>
                      </div>
                    </div>

                    <div className="mt-6">
                      <p className="text-slate-400">El código QR me permitió acceder a información sobre la obra de mi tío, un artista que dejó un gran legado.</p>
                      <ul className="list-none mb-0 text-amber-400 mt-2">
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                      </ul>
                    </div>
                  </li>

                  <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                    <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                      <img src={Client6} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                      <div className="ps-4">
                        <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Elena López</Link>
                        <p className="text-slate-400">User</p>
                      </div>
                    </div>

                    <div className="mt-6">
                      <p className="text-slate-400">El medallón QR es una herramienta muy útil para investigadores y genealogistas, ya que permite acceder a información histórica de una manera rápida y sencilla.</p>
                      <ul className="list-none mb-0 text-amber-400 mt-2">
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="slide h-auto md:w-[360px] w-72 m-2">
                <ul className="space-y-4">
                  <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                    <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                      <img src={Client7} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                      <div className="ps-4">
                        <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Carlos Jiménez</Link>
                        <p className="text-slate-400">User</p>
                      </div>
                    </div>

                    <div className="mt-6">
                      <p className="text-slate-400">Me encanta la idea del medallón QR, es una forma moderna y original de honrar la memoria de nuestros seres queridos.</p>
                      <ul className="list-none mb-0 text-amber-400 mt-2">
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                      </ul>
                    </div>
                  </li>

                  <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                    <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                      <img src={Client8} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                      <div className="ps-4">
                        <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">María López</Link>
                        <p className="text-slate-400">User</p>
                      </div>
                    </div>

                    <div className="mt-6">
                      <p className="text-slate-400">Recuerda a tus seres queridos de una forma especial con el medallón QR.</p>
                      <ul className="list-none mb-0 text-amber-400 mt-2">
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="slide h-auto md:w-[360px] w-72 m-2">
                <ul className="space-y-4">
                  <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                    <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                      <img src={Client1} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                      <div className="ps-4">
                        <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Iñaki Gabilondo</Link>
                        <p className="text-slate-400">User</p>
                      </div>
                    </div>

                    <div className="mt-6">
                      <p className="text-slate-400">Me parece una idea muy original y creativa. Es una forma de darle un nuevo significado a las tumbas y de convertirlas en espacios interactivos.</p>
                      <ul className="list-none mb-0 text-amber-400 mt-2">
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                      </ul>
                    </div>
                  </li>

                  <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                    <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                      <img src={Client2} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                      <div className="ps-4">
                        <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Carmen Mola</Link>
                        <p className="text-slate-400">User</p>
                      </div>
                    </div>

                    <div className="mt-6">
                      <p className="text-slate-400">El medallón QR es una iniciativa que me parece muy interesante y valiosa. Es una forma de mantener viva la memoria de nuestros seres queridos y de compartir su legado con las nuevas generaciones.</p>
                      <ul className="list-none mb-0 text-amber-400 mt-2">
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="slide h-auto md:w-[360px] w-72 m-2">
                <ul className="space-y-4">
                  <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                    <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                      <img src={Client1} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                      <div className="ps-4">
                        <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Thomas Israel</Link>
                        <p className="text-slate-400">User</p>
                      </div>
                    </div>

                    <div className="mt-6">
                      <p className="text-slate-400">Escanear el código QR me permitió sentirme cerca de mi abuela, como si estuviera hablando con ella de nuevo.</p>
                      <ul className="list-none mb-0 text-amber-400 mt-2">
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                      </ul>
                    </div>
                  </li>

                  <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                    <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                      <img src={Client2} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                      <div className="ps-4">
                        <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Barbara McIntosh</Link>
                        <p className="text-slate-400">User</p>
                      </div>
                    </div>

                    <div className="mt-6">
                      <p className="text-slate-400">Ver fotos y videos de mi padre en el medallón QR me trajo una gran alegría y me hizo recordar los mejores momentos que pasamos juntos.</p>
                      <ul className="list-none mb-0 text-amber-400 mt-2">
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="slide h-auto md:w-[360px] w-72 m-2">
                <ul className="space-y-4">
                  <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                    <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                      <img src={Client3} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                      <div className="ps-4">
                        <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Carl Oliver</Link>
                        <p className="text-slate-400">User</p>
                      </div>
                    </div>

                    <div className="mt-6">
                      <p className="text-slate-400">El medallón QR es un regalo invaluable que me permite mantener vivo el recuerdo de mi esposa y compartir su historia con nuestros hijos.</p>
                      <ul className="list-none mb-0 text-amber-400 mt-2">
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                      </ul>
                    </div>
                  </li>

                  <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                    <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                      <img src={Client4} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                      <div className="ps-4">
                        <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Miguel Fernández</Link>
                        <p className="text-slate-400">User</p>
                      </div>
                    </div>

                    <div className="mt-6">
                      <p className="text-slate-400">Gracias al medallón QR, pude conocer detalles de la vida de mi abuelo que nunca antes había escuchado.</p>
                      <ul className="list-none mb-0 text-amber-400 mt-2">
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="slide h-auto md:w-[360px] w-72 m-2">
                <ul className="space-y-4">
                  <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                    <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                      <img src={Client5} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                      <div className="ps-4">
                        <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Sofía Martínez</Link>
                        <p className="text-slate-400">User</p>
                      </div>
                    </div>

                    <div className="mt-6">
                      <p className="text-slate-400">El código QR me permitió acceder a información sobre la obra de mi tío, un artista que dejó un gran legado.</p>
                      <ul className="list-none mb-0 text-amber-400 mt-2">
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                      </ul>
                    </div>
                  </li>

                  <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                    <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                      <img src={Client6} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                      <div className="ps-4">
                        <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Elena López</Link>
                        <p className="text-slate-400">User</p>
                      </div>
                    </div>

                    <div className="mt-6">
                      <p className="text-slate-400">El medallón QR es una herramienta muy útil para investigadores y genealogistas, ya que permite acceder a información histórica de una manera rápida y sencilla.</p>
                      <ul className="list-none mb-0 text-amber-400 mt-2">
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="slide h-auto md:w-[360px] w-72 m-2">
                <ul className="space-y-4">
                  <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                    <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                      <img src={Client7} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                      <div className="ps-4">
                        <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Carlos Jiménez</Link>
                        <p className="text-slate-400">User</p>
                      </div>
                    </div>

                    <div className="mt-6">
                      <p className="text-slate-400">Me encanta la idea del medallón QR, es una forma moderna y original de honrar la memoria de nuestros seres queridos.</p>
                      <ul className="list-none mb-0 text-amber-400 mt-2">
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                      </ul>
                    </div>
                  </li>

                  <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                    <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                      <img src={Client8} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                      <div className="ps-4">
                        <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">María López</Link>
                        <p className="text-slate-400">User</p>
                      </div>
                    </div>

                    <div className="mt-6">
                      <p className="text-slate-400">Recuerda a tus seres queridos de una forma especial con el medallón QR.</p>
                      <ul className="list-none mb-0 text-amber-400 mt-2">
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="slide h-auto md:w-[360px] w-72 m-2">
                <ul className="space-y-4">
                  <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                    <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                      <img src={Client1} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                      <div className="ps-4">
                        <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Iñaki Gabilondo</Link>
                        <p className="text-slate-400">User</p>
                      </div>
                    </div>

                    <div className="mt-6">
                      <p className="text-slate-400">Me parece una idea muy original y creativa. Es una forma de darle un nuevo significado a las tumbas y de convertirlas en espacios interactivos.</p>
                      <ul className="list-none mb-0 text-amber-400 mt-2">
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                      </ul>
                    </div>
                  </li>

                  <li className="rounded-lg shadow dark:shadow-gray-800 p-6 border-b-4 border-amber-400">
                    <div className="flex items-center pb-6 border-b border-gray-200 dark:border-gray-800">
                      <img src={Client2} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                      <div className="ps-4">
                        <Link to="" className="text-lg hover:text-amber-400 duration-500 ease-in-out">Carmen Mola</Link>
                        <p className="text-slate-400">User</p>
                      </div>
                    </div>

                    <div className="mt-6">
                      <p className="text-slate-400">El medallón QR es una iniciativa que me parece muy interesante y valiosa. Es una forma de mantener viva la memoria de nuestros seres queridos y de compartir su legado con las nuevas generaciones.</p>
                      <ul className="list-none mb-0 text-amber-400 mt-2">
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                        <li className="inline"><i className="mdi mdi-star"></i></li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}