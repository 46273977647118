import React from "react";
import { FaCross, FaEye, FaTrash } from "react-icons/fa";
import { MdEditDocument } from "react-icons/md";
import { Link } from "react-router-dom";
import type { ProfileEntity } from '../../types/ProfileEntity';

interface CardProfileProps {
  profile: ProfileEntity,
  index: number
  setProfileToDelete: (value: ProfileEntity) => void;
  setShowDeleteModal: (value: boolean) => void;
}

export const CardProfile: React.FC<CardProfileProps> = ({ profile, index, setProfileToDelete, setShowDeleteModal }) => {

  return (
    <div className="relative bg-white dark:bg-slate-900 p-4 rounded-md shadow dark:shadow-gray-700 angel-card" key={index}>
      <img src={process.env.REACT_APP_API_URL + profile.attributes.photo.data.attributes.url} className="rounded-md shadow dark:shadow-gray-700" alt="" />
      <div className="pt-4">
        <div className="flex justify-between profiles-center">
          <span className="text-slate-400">{profile.attributes.name + " " + profile.attributes.lastname}</span>
          <div className="flex profiles-center space-x-2 self-start">
            <Link className="focus:outline-none p-2 bg-amber-400 text-white rounded-md ml-2" to={`/medallion/${profile.attributes.medallion.data.attributes.slug}`} state={{ profile }}>
              <FaEye />
            </Link>
            <Link className="focus:outline-none p-2 bg-amber-400 text-white rounded-md ml-2" to={`/editprofile/${profile.id}`} state={{ profile }}>
              <MdEditDocument />
            </Link>
            <button onClick={() => { setProfileToDelete(profile); setShowDeleteModal(true); }} className="focus:outline-none rounded-md p-2 bg-red-500 text-white ml-2">
              <FaTrash />
            </button>
          </div>
        </div>

        <div className="mt-5 flex justify-between profiles-center">
          <span className="flex profiles-center">
            <p className="ms-1 text-slate-400">{profile.attributes.relationship.data.attributes.name}</p>
          </span>
          <span className="flex profiles-center">
            <FaCross className="h-4 w-4" />
            <span className="ms-1 text-slate-400">{profile.attributes.death_date.toString()}</span>
          </span>
        </div>
      </div>
    </div>
  )
}