import React from "react";
import { Link } from "react-router-dom";
import errorImg from "../../assets/images/logos/light-1.png"

export const Error: React.FC = () => {

  return (
    <>
      <section className="relative overflow-hidden h-screen flex items-center bg-amber-400/5 dark:bg-amber-400/10">
        <div className="container relative">
          <div className="lg:flex justify-center">
            <div className="lg:w-1/2">
              <div className="mt-8">
                <img src={errorImg} className="max-w-md mx-auto" alt="" />

                <div className="text-center">
                  <h5 className="mb-4 md:text-5xl text-3xl md:leading-normal leading-normal tracking-wider font-bold">404</h5>
                  <div className="mt-6">
                    <Link to="/" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-amber-400 hover:bg-amber-500 border-amber-400 hover:border-amber-500 text-white rounded-md">Back to Home</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Switcher /> */}
    </>
  );
}
