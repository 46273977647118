import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useQueryMedallionsBySlug } from '../../models/medallions/useQueryMedallionsBySlug';
import { Signup } from "../Signup";
import { Error } from "../Error"
import { Memorial } from "../../components/Memorial";
import { MemorialSelect } from "../../components/MemorialSelect";
import { Spinner } from '../../components/Spinner';
import type { ProfileEntity } from '../../types/ProfileEntity';
import { useUser } from "../../contexts/UserContext";

export const Medallion: React.FC = () => {
  const { clearUser } = useUser();
  let params = useParams();
  const { responseData, loading } = useQueryMedallionsBySlug(params.slug);
  const registered = responseData && responseData[0] && responseData[0].attributes.registered;
  const medallionId = responseData && responseData[0] && responseData[0].id
  const { state } = useLocation();
  const [profile,] = useState<ProfileEntity | undefined>(state?.profile);

  if (loading) {
    return (
      <Spinner />
    )
  }

  clearUser();

  if (profile) {
    return (
      <section className="relative py-8 bg-gradient-to-b from-amber-400/20 dark:from-amber-400/40 to-transparent">
        <div className="relative flex justify-center flex-col">
          <div id="StarterContent" className="mt-6">
            <Memorial profile={profile} />
          </div>
        </div>
      </section>
    )
  }

  return (
    <>
      {!medallionId && (
        <Error />
      )}
      {medallionId && !registered && (
        <Signup medallionId={medallionId} />
      )}
      {medallionId && registered && (
        (() => {
          switch (responseData[0].attributes.profiles.data.length) {
            case 0:
              return <Error />;
            case 1:
              return (
                <section className="relative py-8 bg-gradient-to-b from-amber-400/20 dark:from-amber-400/40 to-transparent">
                  <div className="relative flex justify-center flex-col">
                    <div id="StarterContent" className="mt-6">
                      <Memorial profile={responseData[0].attributes.profiles.data[0]} />
                    </div>
                  </div>
                </section>
              );
            default:
              return <MemorialSelect profiles={responseData[0].attributes.profiles.data} />;
          }
        })()
      )}
    </>
  );
};
