import React, { useState } from "react";
import { Link } from "react-router-dom";
import logoDark from '../../assets/images/logos/light-3.png'
import logoLight from '../../assets/images/logos/light-3.png'
import { useUser } from "../../contexts/UserContext";

export const Navbar: React.FC = () => {
  const [toggleMenu, setToggleMenu] = useState(false)
  //const [scroll, ] = useState(false);
  const { user, clearUser } = useUser();

  const handleLogout = () => {
    clearUser();
  }

  return (
    <nav id="topnav" /* className={`${scroll ? "nav-sticky" : ""} defaultscroll is-sticky`} */>
      <div className="container flex justify-center">
        <Link className="logo" to="/">
          <img src={logoDark} className="h-8 inline-block dark:hidden" alt="" />
          <img src={logoLight} className="h-8 hidden dark:inline-block" alt="" />
        </Link>

        <div className="menu-extras">
          <div className="menu-item">
            <Link className={`${toggleMenu ? 'open' : ''} navbar-toggle`} onClick={() => setToggleMenu(!toggleMenu)} to={""}>
              <div className="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </Link>
          </div>
        </div>

        <div id="navigation" className={`${toggleMenu ? 'block' : ''}`}>
          <ul className="navigation-menu">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li><Link to="/aboutus" className="sub-menu-item">Quiénes somos</Link></li>
            <li><Link to="/buy" className="sub-menu-item">Comprar</Link></li>
            <li><Link to="/contact" className="sub-menu-item">Contacto</Link></li>
            {
              user ?
                <>
                  <li>
                    <Link to="/dashboard">
                      <span className="py-[6px] px-4 md:inline hidden items-center justify-center tracking-wider align-middle duration-500 text-sm text-center rounded bg-amber-400/5 hover:bg-amber-400 border border-amber-400/10 hover:border-amber-400 text-amber-400 hover:text-white font-semibold">Dashboard</span>
                      <span className="py-[6px] px-4 inline md:hidden items-center justify-center tracking-wider align-middle duration-500 text-sm text-center rounded bg-amber-400 hover:bg-amber-500 border border-amber-400 hover:border-amber-500 text-white font-semibold">Dashboard</span>
                    </Link>
                  </li>

                  <li>
                    <Link onClick={handleLogout} to={""}>
                      <span className="py-[6px] px-4 md:inline hidden items-center justify-center tracking-wider align-middle duration-500 text-sm text-center rounded bg-amber-400 border border-amber-400  text-white font-semibold">
                        Salir
                      </span>
                    </Link>
                  </li>
                </>
                :
                <li>
                  <Link to="/login">
                    <span className="py-[6px] px-4 md:inline hidden items-center justify-center tracking-wider align-middle duration-500 text-sm text-center rounded bg-amber-400/5 hover:bg-amber-400 border border-amber-400/10 hover:border-amber-400 text-amber-400 hover:text-white font-semibold">Login</span>
                    <span className="py-[6px] px-4 inline md:hidden items-center justify-center tracking-wider align-middle duration-500 text-sm text-center rounded bg-amber-400 hover:bg-amber-500 border border-amber-400 hover:border-amber-500 text-white font-semibold">Login</span>
                  </Link>
                </li>
            }
          </ul>

        </div>

      </div>
    </nav>
  )
}