import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from '../../assets/images/logos/light-1.png'
import { useForgotPassword } from '../../models/forgotPassword/useForgotPassword'
import { useNavigate } from 'react-router-dom';
import { useUser } from "../../contexts/UserContext";

export const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const [email, setEmail] = useState('');
  const [disabled, setDisabled] = useState(true);
  const { handleForgotPasswor, error, loading } = useForgotPassword({ email });

  const validateFields = useCallback((): boolean => {
    if (!email) {
      return true;
    }
    return false;
  }, [email]);

  useEffect(() => {
    if (validateFields()) {
      setDisabled(true)
    } else {
      setDisabled(false);
    }
  }, [validateFields]);


  useEffect(() => {
    if (user) {
      navigate('/dashboard');
    }
  }, [navigate, user]);

  return (
    <>
      <section className="relative overflow-hidden h-screen flex items-center bg-[url('../../assets/images/bg/n1.png')] bg-no-repeat bg-left bg-cover bg-fixed">
        <div className="absolute inset-0 bg-slate-950/20"></div>
        <div className="container relative">
          <div className="md:flex justify-center">
            <div className="lg:w-1/3 md:w-2/4">
              <div className="rounded shadow bg-white dark:bg-slate-900 p-6">
                <Link to="/">
                  <img src={logo} alt="" />
                </Link>

                <h5 className="mt-6 text-xl font-semibold">Recupera tu contraseña.</h5>

                <form className="text-start mt-4">
                  <div className="grid grid-cols-1">
                    <div className="mb-4">
                      <label className="font-semibold" htmlFor="LoginEmail">Correo Electrónico:</label>
                      <input
                        id="LoginEmail"
                        type="email"
                        className="form-input mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>

                    <div className="mb-4 text-center">
                      {error && <label className="font-semibold text-red-600">{error.message}</label>}
                    </div>

                    <div className="mb-4">
                      <input
                        className="py-2 px-5 inline-block tracking-wide border align-middle duration-500 text-base text-center bg-amber-400 hover:bg-amber-500 border-amber-400 hover:border-amberbg-amber-500 text-white rounded-md w-full"
                        value={loading ? "Enviando" : "Enviar Correo"}
                        onClick={handleForgotPasswor}
                        disabled={disabled || loading}
                      />
                    </div>

                    <div className="mb-4">
                      <Link
                        to="/"
                        className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-gray-800/5 hover:bg-gray-800 border-gray-800/10 hover:border-gray-800 text-gray-800 dark:text-white hover:text-white rounded-md w-full"
                      >
                        Regresar
                      </Link>
                    </div>

                    {/* <div className="text-center">
                      <span className="text-slate-400 me-2">¿No tienes una cuenta?</span> <Link to="/signup" className="text-slate-900 dark:text-white font-bold inline-block">Regístrate</Link>
                    </div> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Switcher /> */}
    </>
  );
}
