import { CREATE_CONTACT } from '../../graphql/mutations';
import { useMutation } from '@apollo/client';
import type { ContactEntity } from '../../types/ContactEntity';

interface useCreateContactProps {
  name: string;
  email: string;
  comment: string;
  question: string;
}

export const useCreateContact = (requestData: useCreateContactProps) => {
  const [createContact, { loading, error, data }] = useMutation(CREATE_CONTACT);

  const handleCreateContact = async () => {
    try {

      const response = await createContact({
        variables: {
          data: {
            name: requestData.name,
            email: requestData.email,
            comment: requestData.comment,
            question: requestData.question,
          }
        }
      }) as ContactEntity;
      return response;
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return { handleCreateContact, loading, error, data };
};
