import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation login($identifier: String!, $password: String!) {
    login(input: { identifier: $identifier, password: $password }) {
      jwt
      user {
        id
      }
    }
  }
`;

export const REGISTER = gql`
  mutation register($username: String!, $email: String!, $password: String!) {
    register(
      input: { username: $username, email: $email, password: $password }
    ) {
      jwt
      user {
        id
      }
    }
  }
`;

export const UPLOAD = gql`
  mutation UPLOAD($file: Upload!) {
    upload(file: $file) {
      data {
        id
        attributes{
          url
        }
      }
    }
  }
`;

export const MULTIPLE_UPLOAD = gql`
  mutation MULTIPLE_UPLOAD($files: [Upload]!) {
    multipleUpload(files: $files) {
      data {
        id
      }
    }
  }
`;

export const CREATE_PROFILE = gql`
  mutation createProfile(
    $name: String!
    $lastname: String!
    $title: String
    $photo: ID!
    $gallery: [ID]
    $headline: String
    $obituary_link: String
    $legacy_link: String
    $video_link: String
    $video_links: JSON
    $bio: String
    $birth_date: Date!
    $death_date: Date!
    $phrase: String
    $relationship: ID!
    $medallion: ID!
    $users_permissions_user: ID!
    $grave_address: String
    $grave_location: String
    $grave_latitude: String
    $grave_longitude: String
  ) {
    createProfile(
      data: {
        name: $name
        lastname: $lastname
        title: $title
        photo: $photo
        gallery: $gallery
        headline: $headline
        obituary_link: $obituary_link
        legacy_link: $legacy_link
        video_link: $video_link
        video_links: $video_links
        bio: $bio
        birth_date: $birth_date
        death_date: $death_date
        phrase: $phrase
        relationship: $relationship
        medallion: $medallion
        users_permissions_user: $users_permissions_user
        grave_address: $grave_address
        grave_location: $grave_location
        grave_latitude: $grave_latitude
        grave_longitude: $grave_longitude
      }
    ) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_MEDALLION = gql`
  mutation updateMedallion($id: ID!, $data: MedallionInput!){
    updateMedallion(id: $id, data: $data){
      data{
        id
        attributes {
          slug
          registered
          users_permissions_user {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

export const DELETE_PROFILE = gql`
  mutation deleteProfile($id: ID!){
    deleteProfile(id: $id){
      data{
        id
      }
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation updateProfile($id: ID!, $data: ProfileInput!){
    updateProfile(id: $id, data: $data){
      data{
        id
      }
    }
  }
`;

export const CREATE_MEDALLION = gql`
  mutation createMedallion($data: MedallionInput!){
    createMedallion(data: $data){
      data{
        id
      }
    }
  }
`;

export const CREATE_TRIBUTE = gql`
  mutation createTribute($data: TributeInput!) {
    createTribute(data: $data) {
      data {
        id
        attributes {
          name
          email
          createdAt
          comment
          media {
            data {
              attributes {
                url
              }
            }
          }
          profile {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

export const CREATE_CONTACT = gql`
  mutation createContact($data: ContactInput!) {
    createContact(data: $data) {
      data {
        id
      }
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!){
    forgotPassword(email: $email){
      ok
    }
  }
`;

export const DELETE_TRIBUTE = gql`
  mutation deleteTribute($id: ID!){
    deleteTribute(id: $id){
      data{
        id
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUsersPermissionsUser(
    $id: ID!
    $data: UsersPermissionsUserInput!
  ) {
    updateUsersPermissionsUser(id: $id, data: $data){
      data{
        id
        attributes{
          email
          username
          photo{
            data{
              attributes{
                url
              }
            }
          }
        }
      }
    }
  }
`;
