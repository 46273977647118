import { RELATIONSHIPS } from '../../graphql/query';
import { useQuery } from '@apollo/client';
import type { RelationshipEntity } from '../../types/RelationshipEntity';

export const useQueryRelationship = () => {
  let responseData: RelationshipEntity[] | undefined = undefined;
  const { loading, data } = useQuery(RELATIONSHIPS, {
    variables: { pageSize: 50 },
  });

  if (!loading ) {
    responseData = data.relationships.data
  }

  return { responseData };
};
