import { TRIBUTES_BY_PROFILE_ID } from '../../graphql/query';
import { useQuery } from '@apollo/client';
import type { TributeEntity } from '../../types/TributeEntity';

export const useQueryTributesByProfileId = (profileId: number) => {
  let responseData: TributeEntity[] | undefined = undefined;
  const { loading, data, refetch } = useQuery(TRIBUTES_BY_PROFILE_ID, {
    variables: { profileId: profileId, pageSize: 50 },
    skip: !profileId,
    fetchPolicy: 'network-only', 
  });

  if (!loading && profileId) {
    responseData = data.tributes.data as TributeEntity[]
  }

  return { responseData, loading, refetch };
};
