import { FORGOT_PASSWORD } from '../../graphql/mutations';
import { useMutation } from '@apollo/client';

interface useCreateContactProps {
  email: string;
}

export const useForgotPassword = (requestData: useCreateContactProps) => {
  const [forgotPasswor, { loading, error }] = useMutation(FORGOT_PASSWORD);

  const handleForgotPasswor = async () => {
    try {
      const response = await forgotPasswor({
        variables: {
          email: requestData.email,
        }
      }) as unknown;
      return response;
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return { handleForgotPasswor, loading, error };
};