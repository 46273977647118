import { DELETE_PROFILE } from '../../graphql/mutations';
import { useMutation } from '@apollo/client';
import type { ProfileEntity } from '../../types/ProfileEntity';

export const useDeleteProfile = (profileId?: number) => {
    const [deleteProfile, { loading, error }] = useMutation(DELETE_PROFILE);
    let responseData: ProfileEntity | undefined = undefined;
    const handleDeleteProfile = async () => {
        try {
            const { data } = await deleteProfile({ variables: { id: profileId } });
            responseData = data.deleteProfile.data as ProfileEntity
            return responseData;
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return { handleDeleteProfile, loading, error };
};
