import React, { useCallback, useEffect, useState } from 'react';
import { FiCamera, FiMail, FiMessageCircle, FiUser } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import type { ProfileEntity } from '../../types/ProfileEntity';
import cross from "../../assets/images/cruz.jpg"
import star from "../../assets/images/star.webp"
import logo from "../../assets/images/logos/light-1.png"
import { Helmet } from 'react-helmet';
import { useCreateTribute } from "../../models/tributes/useCreateTribute"
import { useQueryTributesByProfileId } from "../../models/tributes/useQueryTributesByProfileId";
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { FaFacebook, FaTwitter, FaWhatsapp } from 'react-icons/fa';

interface MemorialProps {
  profile: ProfileEntity;
}

interface Field {
  id: number;
  value: string;
}

export const Memorial: React.FC<MemorialProps> = ({ profile }) => {
  const [activeTab, setActiveTab] = useState(1);
  const { responseData, loading, refetch } = useQueryTributesByProfileId(profile.id)
  const [media, setMedia] = useState<File>();
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [errorRequest, setErrorRequest] = useState('');
  const [, setDisabled] = useState(true);
  const [videoLinks,] = useState<Field[]>(profile.attributes.video_links as unknown as Field[]);
  const url = window.location.href
  const createTibute = useCreateTribute({
    name,
    media,
    comment,
    email,
    profile: profile.id,
  });

  const handleTabClick = (tabIndex: React.SetStateAction<number>) => {
    setActiveTab(tabIndex);
  };
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const handleCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(event.target.value);
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handleMediaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const mediaFile = event.target.files[0];
      setMedia(mediaFile);
    }
  };
  const validateFields = useCallback((): boolean => {
    if (!name || !email || !comment) {
      return true;
    }
    return false;
  }, [name, email, comment]);
  const handleSubmit = async () => {
    setErrorRequest('');
    if (validateFields()) {
      setErrorRequest("Los nombre, el email y mensaje son obligatorios.")
    } else {
      try {
        await createTibute.handleCreateTribute();
        await refetch();
        setMedia(undefined);
        setName("");
        setEmail("");
        setComment("");
      } catch (error) {
        setErrorRequest('Error al crear el tributo');
      }
    }
  };
  const extractYouTubeID = (urlOrIframe: string): string | null => {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/]+\/[^/]+|(?:v|embed|watch)(?:\.php)?(?:\?v=|\/))|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = urlOrIframe.match(regex);

    return match ? match[1] : null;
  };

  useEffect(() => {
    if (validateFields()) {
      setDisabled(true)
    } else {
      setDisabled(false);
    }
  }, [validateFields]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{profile.attributes.name + " " + profile.attributes.lastname}</title>
        <link rel="icon" href={process.env.REACT_APP_API_URL + profile.attributes.photo.data.attributes.url} />
        <link rel="apple-touch-icon" href={process.env.REACT_APP_API_URL + profile.attributes.photo.data.attributes.url} />
        <meta name="description" content={String(profile.attributes.birth_date) + " - " + String(profile.attributes.death_date)} />
      </Helmet>
      <section className="relative">
        <div className="container relative">
          <div className="md:flex justify-center">
            <div className="md:w-4/5">
              {profile.attributes.headline &&
                <p className="text-slate-400 text-lg mt-3">{profile.attributes.headline}</p>
              }
              {profile.attributes.title &&
                <Link to="" className="bg-amber-400 text-white text-[12px] font-semibold px-2.5 py-0.5 rounded h-5">{profile.attributes.title}</Link>
              }
              <h5 className="md:text-4xl text-3xl font-bold md:tracking-normal tracking-normal md:leading-normal leading-normal">{profile.attributes.name + " " + profile.attributes.lastname}</h5>
              <div className='flex flex-row justify-around'>
                <div className="flex items-center mt-5">
                  <img src={star} className="h-12 w-12 rounded-full" alt="" />
                  <div className="ms-2">
                    <span className="text-slate-400 text-xl">{String(profile.attributes.birth_date)}</span>
                  </div>
                </div>
                <div className="flex items-center mt-5">
                  <img src={cross} className="h-12 w-12 rounded-full" alt="" />
                  <div className="ms-2">
                    <span className="text-slate-400 text-xl">{String(profile.attributes.death_date)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="relative md:pb-24 pb-16 pt-7">
        <div className="container relative">
          <div className="md:flex justify-center">
            <div className="md:w-4/5">
              <div className="w-full photo-profile">
                <img src={process.env.REACT_APP_API_URL + profile.attributes.photo.data.attributes.url} className="rounded-md min-w-[50%]" alt="" />
              </div>
              {profile.attributes.phrase &&
                <div className="relative rounded-md border-s-4 border-amber-400 px-6 py-10 mt-4">
                  <p className="text-2xl font-medium">“ {profile.attributes.phrase} “</p>
                  <div className="absolute text-8xl -top-0 start-4 text-amber-500/10 dark:text-amber-500/20 -z-1">
                    <i className="mdi mdi-format-quote-open"></i>
                  </div>
                </div>
              }
              {profile.attributes.bio && <p className="text-slate-400 mt-4 whitespace-pre-line">{profile.attributes.bio}</p>}
              {profile.attributes.obituary_link &&
                <div className="relative rounded-md border-s-4 border-amber-400 px-6 py-10 mt-4">
                  <p className="text-2xl font-medium">Enlace a obituario público:</p>
                  <Link className="text-sm font-medium" target="_blank" to={profile.attributes.obituary_link}>{profile.attributes.obituary_link}</Link>
                </div>
              }
              {profile.attributes.legacy_link &&
                <div className="relative rounded-md border-s-4 border-amber-400 px-6 py-10 mt-4">
                  <p className="text-2xl font-medium">Enlace a legado:</p>
                  <Link className="text-sm font-medium" target="_blank" to={profile.attributes.legacy_link}>{profile.attributes.legacy_link}</Link>
                </div>
              }
              <div className="relative py-10 mt-4">
                <p className="text-2xl font-medium">Compartir:</p>
                <WhatsappShareButton
                  url={url}
                  title={profile.attributes.name + " " + profile.attributes.lastname}
                  className="p-2">
                  <FaWhatsapp className="share-button" />
                </WhatsappShareButton>
                <FacebookShareButton
                  url={url}
                  title={profile.attributes.name + " " + profile.attributes.lastname}
                  className="p-2">
                  <FaFacebook className="share-button" />
                </FacebookShareButton>
                <TwitterShareButton
                  url={url}
                  title={profile.attributes.name + " " + profile.attributes.lastname}
                  className="p-2">
                  <FaTwitter className="share-button" />
                </TwitterShareButton>
              </div>
              <div className="border-b border-gray-200 dark:border-gray-700 mt-8">
                <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400 justify-center">
                  <li className={`${activeTab === 1 && "bg-amber-200 shadow rounded-md"} me-2`}>
                    <Link onClick={() => handleTabClick(1)} className="inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group" to={''}>
                      Tributos
                    </Link>
                  </li>
                  {profile.attributes.gallery.data.length > 1 &&
                    <li className={`${activeTab === 2 && "bg-amber-200 shadow rounded-md"} me-2`}>
                      <Link onClick={() => handleTabClick(2)} className="inline-flex items-center justify-center p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group" aria-current="page" to={''}>
                        Fotos
                      </Link>
                    </li>
                  }
                  {videoLinks[0].value &&
                    <li className={`${activeTab === 3 && "bg-amber-200 shadow rounded-md"} me-2`}>
                      <Link onClick={() => handleTabClick(3)} className="inline-flex items-center justify-center p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group" aria-current="page" to={''}>
                        Vídeos
                      </Link>
                    </li>
                  }
                  {(profile.attributes.grave_address || profile.attributes.grave_longitude || profile.attributes.grave_latitude || profile.attributes.grave_location) &&
                    <li className={`${activeTab === 4 && "bg-amber-200 shadow rounded-md"} me-2`}>
                      <Link onClick={() => handleTabClick(4)} className="inline-flex items-center justify-center p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group" aria-current="page" to={''}>
                        Dirección
                      </Link>
                    </li>
                  }
                </ul>
              </div>
              {activeTab === 1 && (
                <section>
                  <div className="mt-6">
                    <h5 className="text-xl font-semibold">Tributos: </h5>
                    {!loading && responseData && responseData.length >= 1 && responseData.map((tribute) => (
                      <div className="mt-8" key={tribute.id}>
                        <div className="p-4 bg-gray-50 dark:bg-slate-800 rounded-md shadow dark:shadow-gray-800 mt-6 flex text-center flex-col">
                          {tribute.attributes.media.data &&
                            <img src={process.env.REACT_APP_API_URL + tribute.attributes.media.data.attributes.url} className="rounded-md min-w-[50%] pb-4" alt="" />
                          }
                          <p className="text-slate-400 italic">" {tribute.attributes.comment} "</p>
                        </div>
                        <div className="flex items-center justify-between mt-2">
                          <div className="flex items-center">
                            <div className="flex-1">
                              <p className="font-semibold">{tribute.attributes.name}</p>
                              <p className="text-sm text-slate-400">{String(tribute.attributes.createdAt).split('T')[0]}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="mt-14">
                    <h5 className="text-xl font-semibold">Deja un tributo:</h5>

                    <div className="mt-6">
                      <div className="grid lg:grid-cols-12 lg:gap-6">
                        <div className="lg:col-span-4 mb-5">
                          <div className="text-start">
                            <label htmlFor="name" className="font-semibold">Nombre:</label>
                            <div className="form-icon relative mt-2">
                              <FiUser className="w-4 h-4 absolute top-3 start-4" />
                              <input
                                name="name"
                                id="name"
                                type="text"
                                className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0"
                                placeholder="Nombre :"
                                value={name}
                                onChange={handleNameChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="lg:col-span-4 mb-5">
                          <div className="text-start">
                            <label htmlFor="email" className="font-semibold">Email:</label>
                            <div className="form-icon relative mt-2">
                              <FiMail className="w-4 h-4 absolute top-3 start-4" />
                              <input
                                name="email"
                                id="email"
                                type="email"
                                className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0"
                                placeholder="Email :"
                                value={email}
                                onChange={handleEmailChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="lg:col-span-4 mb-5">
                          <div className="text-start">
                            <label htmlFor="email" className="font-semibold">Foto:</label>
                            <div className="form-icon relative mt-2">
                              <FiCamera className="w-4 h-4 absolute top-3 start-4" />
                              <input
                                name="media"
                                id="media"
                                type="file"
                                className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0"
                                placeholder="Email :"
                                onChange={handleMediaChange}
                              />
                            </div>
                            <label className='text-sm text-slate-400'>{"(Opcional)"}</label>
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-cols-1">
                        <div className="mb-5">
                          <div className="text-start">
                            <label htmlFor="comments" className="font-semibold">Mensaje:</label>
                            <div className="form-icon relative mt-2">
                              <FiMessageCircle className="w-4 h-4 absolute top-3 start-4" />
                              <textarea
                                name="comment"
                                id="comment"
                                className="form-input ps-11 w-full py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0"
                                placeholder="Mensaje :"
                                value={comment}
                                onChange={handleCommentChange}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                      {errorRequest &&
                        <div className="lg:col-span-6 mb-5">
                          <label className="font-semibold text-red-600">{errorRequest}</label>
                        </div>
                      }
                      {createTibute.errorResponse &&
                        <div className="lg:col-span-6 mb-5">
                          <label className="font-semibold text-red-600">{createTibute.errorResponse.message}</label>
                        </div>
                      }
                      {createTibute.data &&
                        <div className="lg:col-span-6 mb-5">
                          <label className="font-semibold text-green-600">Tributo enviado con éxito</label>
                        </div>
                      }
                      <button
                        disabled={createTibute.loading}
                        onClick={handleSubmit}
                        className="py-2 px-5 inline-block tracking-wide border align-middle duration-500 text-base text-center bg-amber-400 hover:bg-amberborder-amber-500 border-amber-400 hover:border-amber-500 text-white rounded-md w-full"
                      >
                        {createTibute.loading ? "Enviando..." : "Send Message"}
                      </button>
                    </div>
                  </div>
                </section>
              )}
              {profile.attributes.gallery.data.length > 1 && activeTab === 2 && (
                <section className="mt-4 flex flex-col items-center">
                  {profile.attributes.gallery.data.map((photo, index) => (
                    <div className="w-fit bg-white pb-12 mb-8 px-4 pt-4 border rounded-md shadow" key={index}>
                      <img src={process.env.REACT_APP_API_URL + photo.attributes.url} className="rounded-md min-w-[50%]" alt="" />
                    </div>
                  ))}
                </section>
              )}
              {videoLinks[0].value && activeTab === 3 && (
                <section className="mt-4 flex flex-col items-center">
                  {videoLinks.map(videoLink => {
                    return <div className="bg-white border rounded-md shadow w-full mb-8">
                      <iframe
                        className='w-full'
                        height={450}
                        /* src={`https://www.youtube.com/embed/${videoLink.value.split("=").slice(-1)}`} */
                        src={`https://www.youtube.com/embed/${extractYouTubeID(videoLink.value)}`}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        frameBorder="0"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                      >
                      </iframe>
                    </div>
                  })}

                </section>
              )}
              {(profile.attributes.grave_address || profile.attributes.grave_longitude || profile.attributes.grave_latitude || profile.attributes.grave_location) && activeTab === 4 && (
                <section>
                  <div className="mt-14">
                    <h5 className="text-xl font-semibold">Dirección del sepulcro:</h5>
                    <div className="mt-6">
                      <div className="grid lg:grid-cols-12 lg:gap-6">
                        <div className="lg:col-span-4 mb-5">
                          <div className="text-start">
                            {profile.attributes.grave_longitude && <><label>{profile.attributes.grave_longitude}</label><br /></>}
                            {profile.attributes.grave_address && <><label>{profile.attributes.grave_address}</label><br /></>}
                            {profile.attributes.grave_location && <><label>{profile.attributes.grave_location}</label><br /></>}
                          </div>
                        </div>
                      </div>
                    </div>
                    {profile.attributes.grave_latitude &&
                      <div className="mt-6 mb-5">
                        <div className="text-start w-full">
                          <label >Mapa:</label><br />
                          {/*  <iframe
                            className='w-full'
                            height={450}
                            title='map'
                            src={`${profile.attributes.grave_latitude}`}
                            loading="lazy"
                          >
                          </iframe> */}
                          <Link to={profile.attributes.grave_latitude} target='_blank' className='video-play-icon read-more lightbox hover:text-amber-400'> Ver en google maps</Link>
                        </div>
                      </div>
                    }
                  </div>
                </section>
              )}
            </div>
          </div>
        </div>
      </section>
      <div className="w-full photo-profile">
        <Link to={'/'}><img src={logo} width={200} className="rounded-md" alt="" /></Link>
      </div>
    </>
  );
};
