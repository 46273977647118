import React, { ReactNode, useState } from "react";
import { Link } from "react-router-dom";
import { FiCheckCircle } from "react-icons/fi";
import { useUser } from "../../contexts/UserContext";

interface SidebarProps {
  children: ReactNode;
}

export const Sidebar: React.FC<SidebarProps> = ({ children }) => {
  
  const { clearUser } = useUser();
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = () => {
    clearUser();
  }

  return (
    <>
      <aside className={`bg-slate-900 sticky text-white w-64 sidebar ${isSidebarOpen ? 'block' : 'hidden'}`}>
        <div className="">
          <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">{'Dashboard'}</h3>
          <ul className="list-none text-slate-400 mt-4">
            <Link className="mb-2 flex items-center hover:text-amber-400 font-medium" to="/dashboard"><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /> Inicio</Link>
            <Link className="mb-2 flex items-center hover:text-amber-400 font-medium" to="/addprofile"><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /> Agregar Perfil</Link>
            <Link className="mb-2 flex items-center hover:text-amber-400 font-medium" to="/config"><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /> Configuraciones</Link>
            <Link onClick={handleLogout} className="mb-2 flex items-center hover:text-amber-400 font-medium" to={""}><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /> Salir</Link>
          </ul>
        </div>
      </aside>
      <div className="flex">
        <nav className="bg-slate-900 text-white p-4 sidebar-toggle">
          <button className="text-xl pt-2" onClick={toggleSidebar}>☰</button>
        </nav>
        <div className={`${isSidebarOpen ? 'hidden xs:block' : 'block'}`}>
          {children}
        </div>
      </div>
    </>
  )
}