import { Navbar } from '../../components/Navbar';
import { Footer } from "../../components/Footer";
import React, { useState } from "react";
import AboutImg from "../../assets/images/h12.jpg"
import { FiCheckCircle } from "../../assets/icons/vander"
import image3 from '../../assets/images/h8.png'
import image2 from '../../assets/images/h6.png'
import image1 from '../../assets/images/h1.png'

export const AboutUs: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <>
      <Navbar />
      <section className="relative py-32">
        <div className="container relative">
          <div className="grid grid-cols-1 text-center mt-6">
            <div>
              <h5 className="md:text-4xl text-3xl md:leading-normal leading-normal tracking-wider font-semibold text-black mb-0">Quiénes somos</h5>
            </div>
          </div>
        </div>
      </section>
      <section className="relative">

        <div className="container relative">
          <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-6">
            <div className="relative overflow-hidden after:content-[''] after:absolute after:inset-0 after:m-auto after:w-96 after:h-96 after:bg-gradient-to-tl after:to-amber-400 after:from-fuchsia-600 after:blur-[80px] after:rounded-full p-6 bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-slate-800 lg:me-6">
              <div className="relative overflow-hidden rounded-lg shadow-md dark:shadow-gray-800 z-1">
                <img src={AboutImg} alt="" />
              </div>
            </div>

            <div className="">
              <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold"><span className="font-bold">En AngelsQR,</span> <br /> Creemos en la importancia de preservar los recuerdos y honrar las vidas de aquellos que ya no están con nosotros.</h3>
              <p className="text-slate-400 max-w-xl">
                Nos dedicamos apasionadamente a crear una conexión duradera entre los seres queridos y los recuerdos preciados de aquellos que han partido.
                Nuestra empresa nació de la idea de que los recuerdos pueden ser eternos, y a través de la tecnología moderna, hemos encontrado una manera única
                y significativa de mantener viva la memoria de las personas que han dejado una huella en nuestras vidas en 3 simples pasos:
              </p>

              <ul className="list-none text-slate-400 mt-4">
                <li className="mb-2 flex items-center"><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /> Escanea el código QR del medallón y regístrate</li>
                <li className="mb-2 flex items-center"><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /> Llena con los datos necesarios nuestro formulario</li>
                <li className="mb-2 flex items-center"><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /> Recuerda a esa persona por siempre</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="container relative my-16">
          <div className="lg:flex justify-center">
            <div className="lg:w-4/5">
              <ul className="md:flex inline-block w-fit mx-auto flex-wrap justify-center text-center p-2 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
                <li role="presentation" className="inline-block md:w-1/3 w-full p-2">
                  <button className={`${activeIndex === 0 ? "text-white bg-amber-400" : " hover:text-amber-400 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-slate-800"} px-5 py-3 text-start rounded-md w-full duration-500 `} onClick={() => setActiveIndex(0)}>
                    <h5 className="text-base font-semibold">Nuestra Misión</h5>
                    <p className="text-sm mt-1">En AngelsQR, nuestra misión es ofrecer a nuestros clientes una manera innovadora y conmovedora de mantener viva la memoria de sus seres queridos. Nos esforzamos por brindar productos de calidad que honren la vida de quienes han fallecido y brinden consuelo y apoyo a quienes los extrañan.</p>
                  </button>
                </li>
                <li role="presentation" className="inline-block md:w-1/3 w-full p-2">
                  <button className={`${activeIndex === 1 ? "text-white bg-amber-400" : " hover:text-amber-400 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-slate-800"} px-5 py-3 text-start rounded-md w-full duration-500 `} onClick={() => setActiveIndex(1)}>
                    <h5 className="text-base font-semibold">Nuestros Productos</h5>
                    <p className="text-sm mt-1">Tenemos productos diseñados cuidadosamente para preservar los recuerdos de sus seres queridos. Desde medallones hasta códigos QR únicos, cada producto está diseñado para contar la historia de la persona fallecida y proporcionar acceso fácil a información importante y recuerdos compartidos.</p>
                  </button>
                </li>
                <li role="presentation" className="inline-block md:w-1/3 w-full p-2">
                  <button className={`${activeIndex === 2 ? "text-white bg-amber-400" : " hover:text-amber-400 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-slate-800"} px-5 py-3 text-start rounded-md w-full duration-500 `} onClick={() => setActiveIndex(2)} >
                    <h5 className="text-base font-semibold">Nuestro Compromiso</h5>
                    <p className="text-sm mt-1">Desde la calidad de nuestros productos hasta el servicio al cliente excepcional, nos esforzamos por superar las expectativas de nuestros clientes en cada paso del camino. Y estamos aquí para ayudarlo a mantener viva la memoria de sus seres queridos de manera significativa y conmovedora.</p>
                  </button>
                </li>
              </ul>

              <div id="StarterContent" className="mt-6">
                {activeIndex === 0 ?
                  <div>
                    <div className="relative overflow-hidden rounded-lg border border-amber-400/5 bg-gradient-to-tl to-amber-400/30  from-fuchsia-600/30 dark:to-amber-400/50 dark:from-fuchsia-600/50 px-6 pt-6">
                      <img src={image1} className="rounded-t-lg" alt="" />
                    </div>
                  </div> : ''
                }
                {activeIndex === 1 ?
                  <div>
                    <div className="relative overflow-hidden rounded-lg border border-amber-400/5 bg-gradient-to-tl to-amber-400/30  from-fuchsia-600/30 dark:to-amber-400/50 dark:from-fuchsia-600/50 px-6 pt-6">
                      <img src={image2} className="rounded-t-lg" alt="" />
                    </div>
                  </div> : ''
                }
                {activeIndex === 2 ?
                  <div>
                    <div className="relative overflow-hidden rounded-lg border border-amber-400/5 bg-gradient-to-tl to-amber-400/30  from-fuchsia-600/30 dark:to-amber-400/50 dark:from-fuchsia-600/50 px-6 pt-6">
                      <img src={image3} className="rounded-t-lg" alt="" />
                    </div>
                  </div> : ''
                }
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
