import React from "react";
import { Navbar } from '../../components/Navbar';
import { Footer } from "../../components/Footer";
import { Pricing } from "../../components/Pricing"
import { Faq } from "../../components/Faq"

export const Buy: React.FC = () => {

  return (
    <>
      <Navbar />
      <section className="relative pt-32 pb-16">
        <div className="container relative">
          <div className="grid grid-cols-1 text-center mt-6">
            <div>
              <h5 className="md:text-4xl text-3xl md:leading-normal leading-normal tracking-wider font-semibold text-black mb-0">Nuestros Productos</h5>
            </div>
          </div>
        </div>
      </section>

      <section className="relative md:pb-24 pb-16">
        <div className="container relative ">
          <div className="grid grid-cols-1 pb-6 text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Descubre nuestros productos.</h3>

            <p className="text-slate-400 max-w-xl mx-auto">Honra la memoria de tus seres queridos con nuestros medallones y códigos QR únicos: la manera innovadora de honrar y preservar la memoria de tus seres queridos. Celebra su vida con nosotros.</p>
          </div>
          <Pricing />
          <Faq />
        </div>
      </section>
      <Footer />
    </>
  );
}
